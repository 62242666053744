import $ from 'jquery';
/* eslint-disable no-undef */

export const appEnv = process.env.REACT_APP_ENV || 'development';

export const defaults = {
    currency: 'USDT',
    exchange: 'Binance',
    language: 'en-US',
    redirectTime: {
        notFound: 5000,
        unauthorized: 5000
    },
    theme: 'dark',
};

export const currencies = {
    'USDT': 'UDST Theter', 
    'BTC': 'BTC Bitcoin',
    'BRL': 'BRL Brazilian Real'
};

/*export const exchanges = {
    'binance': 'Binance',
    'bybit': 'Bybit',
    'cryptocom': 'Crypto.com',
    'Gemini': 'Gemini',
    'kraken': 'Kraken',
    'kucoin': 'KuCoin'
};*/

export const exchanges = {
    'binance': 'Binance',
    'kucoin': 'KuCoin'
};


export const exchangesPasswordRequired = ['KuCoin'];

export const disregard = {
    symbols: []
};

export const languages = {
    'de-DE': { label: 'Deutsch', icon: 'DE' },
    'en-US': { label: 'English', icon: 'EN' },
    'es-ES': { label: 'Español', icon: 'ES' },
    'fr-FR': { label: 'Français', icon: 'FR' },
    'hi-IN': { label: 'हिन्दी', icon: 'HI' },
    'hu-HU': { label: 'Magyar', icon: 'HU' },
    'it-IT': { label: 'Italiano', icon: 'IT' },
    'ja-JP': { label: '日本語', icon: 'JP' },
    'ko-KR': { label: '한국어', icon: 'KR' },
    'po-PL': { label: 'Polski', icon: 'PO' },
    'pt-BR': { label: 'Português', icon: 'PT' },
    'ru-RU': { label: 'Русский', icon: 'RU' }
};

export const pagesWithToolbar = ['overview', 'dashboard', 'signals', 'news', 'wallet'];

export const proxyURL = 'https://cors-anywhere.herokuapp.com/';

export const userService = {
    hosts: {
        development: 'http://localhost:7000',
        staging: 'https://users.staging.we-bronx.io',
        production: 'https://users.we-bronx.io'
    },
    version: 'v1'
};

export const assets = $.get(`${userService.hosts[appEnv]}/assets`);

export const smallBalance = 1;