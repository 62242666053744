/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

// Ant Design Imports
import { Modal, Button, Checkbox, Input, Row, Col, Avatar, Slider, Form, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

// API Imports
import { atsModesPairsPost, atsModesPairsPatch } from 'apis/imports';

// Icon Imports
import { getIcon, loadIcons } from 'icons/imports';

// Ant Design Select Component
const { Option } = Select;

const AddPairModal = ({ visible, onCancel, mode, refreshPairs, pairToEdit, isEditMode }) => {

    const [form] = Form.useForm();

    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [autoOrder, setAutoOrder] = useState(false);
    const [baseStaticValue, setBaseStaticValue] = useState(false);
    const [baseHasEnough, setBaseHasEnough] = useState(false);
    const [quoteStaticValue, setQuoteStaticValue] = useState(false);
    const [quoteHasEnough, setQuoteHasEnough] = useState(false);
    const [iconMap, setIconMap] = useState({});

    // Slider marks for allocation percentages
    const marks = {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        100: '100%',
    };

    /**
     * Loads icons for exchange, base, and quote symbols when the modal is visible.
     */
    useEffect(() => {
        const loadSymbolsIcons = async () => {
            const symbols = ['kucoin', 'binance', 'btc', 'eth', 'usdt', 'usd'];
            const loadedIcons = await loadIcons(symbols);
            setIconMap(loadedIcons);
        };

        if (visible) {
            loadSymbolsIcons();
        }
    }, [visible]);

    /**
     * Fills the form and sets states when in edit mode.
     */
    useEffect(() => {
        if (isEditMode && pairToEdit) {
            form.setFieldsValue({
                exchange: pairToEdit.exchange,
                baseSymbol: pairToEdit.symbolFrom,
                quoteSymbol: pairToEdit.symbolTo,
                allocationBase: pairToEdit.allocations.from,
                allocationQuote: pairToEdit.allocations.to,
            });

            setEnabled(pairToEdit.enabled);
            setAutoOrder(pairToEdit.createOrder);
            setBaseStaticValue(pairToEdit.baseType === 'Balance');
            setBaseHasEnough(pairToEdit.baseHasEnough);
            setQuoteStaticValue(pairToEdit.quoteType === 'Balance');
            setQuoteHasEnough(pairToEdit.quoteHasEnough);
        }
    }, [isEditMode, pairToEdit, form]);

    /**
     * Handles the form submission and sends the data to the backend API.
     */
    const handleConfirm = async () => {
        try {
            const values = await form.validateFields();

            setLoading(true);

            const base = {
                symbol: values.baseSymbol,
                type: baseStaticValue ? 'Balance' : 'Percentage',
                allocation: parseFloat(values.allocationBase),
                has_enough: baseHasEnough,
            };

            const quote = {
                symbol: values.quoteSymbol,
                type: quoteStaticValue ? 'Balance' : 'Percentage',
                allocation: parseFloat(values.allocationQuote),
                has_enough: quoteHasEnough,
            };

            let result;
            if (isEditMode && pairToEdit) {
                result = await atsModesPairsPatch(mode, pairToEdit.id, {
                    exchangeName: values.exchange,
                    base,
                    quote,
                    enabled,
                    create_order: autoOrder,
                });
            } else {
                result = await atsModesPairsPost({
                    mode,
                    exchangeName: values.exchange,
                    base,
                    quote,
                    enabled,
                    create_order: autoOrder,
                });
            }

            refreshPairs();
            onCancel();
            setLoading(false);
            console.log(result);
        } catch (error) {
            console.error('Error validating form:', error);
        }
    };

    return (
        <Modal
            title={isEditMode ? 'Edit Negotiation Pair' : 'New Negotiation Pair'}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={600}
        >
            <Form form={form} layout="vertical">
                <Row gutter={24} j="space-between" align="middle">
                    <Col span={12}>
                        <Form.Item label="Select Exchange" name="exchange" rules={[{ required: true }]}>
                            <Select placeholder="Select an exchange" disabled={isEditMode}>
                                <Option value="KuCoin">
                                    <Avatar src={getIcon('KuCoin')} style={{ marginRight: '8px' }} /> KuCoin
                                </Option>
                                <Option value="Binance">
                                    <Avatar src={getIcon('Binance')} style={{ marginRight: '8px' }} /> Binance
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Settings">
                            <Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)}>
                                Enabled
                            </Checkbox>
                            <Checkbox
                                checked={autoOrder}
                                onChange={(e) => setAutoOrder(e.target.checked)}
                                style={{ marginTop: '8px' }}
                            >
                                Create orders automatically
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Base Symbol" name="baseSymbol" rules={[{ required: true }]}>
                            <Select placeholder="Select base symbol" disabled={isEditMode}>
                                <Option value="BTC">
                                    <Avatar src={iconMap['btc']} style={{ marginRight: '8px' }} /> Bitcoin
                                </Option>
                                <Option value="ETH">
                                    <Avatar src={iconMap['eth']} style={{ marginRight: '8px' }} /> Ethereum
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Quote Symbol" name="quoteSymbol" rules={[{ required: true }]}>
                            <Select placeholder="Select quote symbol" disabled={isEditMode}>
                                <Option value="USDT">
                                    <Avatar src={iconMap['usdt']} style={{ marginRight: '8px' }} /> Tether
                                </Option>
                                <Option value="USD">
                                    <Avatar src={iconMap['usd']} style={{ marginRight: '8px' }} /> US Dollar
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: '16px' }}>
                    <Col span={12}>
                        <Form.Item label="Allocation">
                            <Checkbox checked={baseStaticValue} onChange={(e) => setBaseStaticValue(e.target.checked)}>
                                Define static value
                            </Checkbox>
                            <Checkbox
                                checked={baseHasEnough}
                                onChange={(e) => setBaseHasEnough(e.target.checked)}
                                style={{ marginTop: '8px' }}
                            >
                                Create order only if have enough balance
                            </Checkbox>
                        </Form.Item>

                        <Form.Item name="allocationBase" rules={[{ required: true, message: 'Please input an allocation' }]}>
                            <Input addonAfter="BTC" />
                        </Form.Item>

                        <Slider marks={marks} defaultValue={100} />
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Allocation">
                            <Checkbox checked={quoteStaticValue} onChange={(e) => setQuoteStaticValue(e.target.checked)}>
                                Define static value
                            </Checkbox>
                            <Checkbox
                                checked={quoteHasEnough}
                                onChange={(e) => setQuoteHasEnough(e.target.checked)}
                                style={{ marginTop: '8px' }}
                            >
                                Create order only if have enough balance
                            </Checkbox>
                        </Form.Item>

                        <Form.Item name="allocationQuote" rules={[{ required: true, message: 'Please input an allocation' }]}>
                            <Input addonAfter="USDT" />
                        </Form.Item>

                        <Slider marks={marks} defaultValue={50} />
                    </Col>
                </Row>

                <Row j="end" style={{ marginTop: '20px' }}>
                    <Button onClick={onCancel} style={{ marginRight: '10px' }}>
                        Cancel
                    </Button>
                    <Button type="primary" icon={<CheckOutlined />} loading={loading} onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddPairModal;
