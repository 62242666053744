import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'components/imports';

export const EmptyDisplay = (props) => {
    return (
        <Column
            a='center'
            fill='all'
            j='center'
        >
            <p>{props.message}</p>
        </Column>
    );
};


EmptyDisplay.propTypes = {
    message: PropTypes.string
};


export default EmptyDisplay;