import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components/imports';

import './Table.css';

// eslint-disable-next-line react/prop-types
function Table({ data }) {

    const location = useLocation();
    const { t } = useTranslation();
    const pageId = 'table';
    const tableRef = useRef(null);

    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            const table = tableRef.current;

            if (table) {
                const headerCells = table.querySelectorAll('thead th');
                const bodyRows = table.querySelectorAll('tbody tr');

                setTimeout(() => {
                    headerCells.forEach((headerCell, index) => {
                        const bodyCells = Array.from(bodyRows).map(row => row.querySelectorAll('td')[index]);
                        const maxWidth = Math.max(...bodyCells.map(cell => cell.offsetWidth));
                        headerCell.style.width = `${maxWidth}px`;

                        bodyCells.forEach(bodyCell => {
                            bodyCell.style.width = `${maxWidth}px`;
                        });
                    });
                }, 1500);
            }
        }
    }, [location.pathname]);

    const applyFormatter = (header, value) => {
        let formatter = header.formatter,
            classes = formatter,
            title = value;

        switch (formatter) {
            case 'side':
                value = value !== '' ? (<Badge variation={value}>{value.toUpperCase()}</Badge>) : <></>;
                break;
            default:
                break;
        }

        return (<span className={classes} title={title}>{value}</span>);
    };

    return (
        <table
            className='fill-width'
            ref={tableRef}
        >
            <thead>
                <tr>
                    {
                        // eslint-disable-next-line react/prop-types
                        data.header.map((item, index) => {
                            return <th key={index}>{t(item.label)}</th>;
                        })
                    }
                </tr>
            </thead>

            <tbody>
                {
                    // eslint-disable-next-line react/prop-types
                    data.body.map((row, index) => {
                        return <tr key={index} className={(index % 2) === 0 ? '' : 'row-bg-color'}>{
                            row.map((item, index) => {
                                // eslint-disable-next-line react/prop-types
                                return <td key={index}>{applyFormatter(data.header[index], item)}</td>;
                            })
                        }</tr>;
                    })
                }
            </tbody>
        </table>
    );
}

export default Table;
