import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

// React Translations
import { useTranslation } from 'react-i18next';

// Components
import { Column, Row } from 'components/imports';

// Icons
import { getIcon, InfoSvg, MoveLeftArrowSvg } from 'icons/imports';


import './Select.css';


const Select = (props) => {

    const { t } = useTranslation();

    const className = props.className ? ' ' + props.className : '';
    const id = props.id;
    const showInfo = props.showInfo ?? true;

    const [showExchangeInfoOpen, setShowExchangeInfoOpen] = useState(false);

    const [lastSelectId, setLastSelectId] = useState('');

    var infoIcon = <InfoSvg className='icon-svg' />;


    $(document).on('click', (e) => {

        if ($(e.target).closest('.select .select-overlap').length === 0) {
            $('.select .select-overlap.open').removeClass('open');

            setLastSelectId('');
        }
    });


    useEffect(() => {

        if (lastSelectId !== '') {

            let selectedId = lastSelectId.split(' ')[0];

            try {
                let selected = $('.select .select-overlap#' + selectedId);

                if (selected.hasClass('open')) {
                    selected.removeClass('open');
                    $(`.select #${selectedId} + .select .select-list`).fadeIn();
                } else {
                    selected.addClass('open');
                    $(`.select #${selectedId} + .select .select-list`).fadeOut();
                }
                setLastSelectId('');
            }
            catch (err) { console.error(err); }
        }

    }, [lastSelectId]);

    switch (props.variation) {

        case 'exchange':
            return (
                <Row id='exchange-selector'
                    className='select exchange-selector'
                    fit='all'
                    g='0'
                >
                    <Column>
                        <div id={id}
                            className={'select-overlap' + className}
                            onClick={() => setLastSelectId(id)}
                        >
                            <Row
                                a={'center'}
                                fill='width'
                                j='between'
                            >
                                <p><b>{props.selected}</b></p>

                                <MoveLeftArrowSvg className='selector-arrow' />
                            </Row>
                        </div>
                        <ul id='exchange-selector-list'
                            className='select-list'
                            hidden
                        >
                            {
                                props.options.map((item, index) => {

                                    var isSelected = item === props.selected;

                                    if (!isSelected) {
                                        return (
                                            <li className={isSelected ? 'selected' : ''}
                                                key={'exchange-' + index}
                                                onClick={() => props.onSelect(item)}
                                            >{item}</li>
                                        );
                                    }
                                })
                            }
                        </ul>
                    </Column >

                    {showInfo &&
                        <Tooltip
                            color='#1D96EE'
                            open={showExchangeInfoOpen}
                            title={
                                <>
                                    <label><b>{t('exchange-selector')}</b></label>

                                    <p>{t('exchange-selector-info')}</p>
                                </>
                            }
                        >
                            <Row id='icon-container'
                                onClick={() => setShowExchangeInfoOpen(!showExchangeInfoOpen)}
                            >
                                {infoIcon}
                            </Row>
                        </Tooltip>
                    }
                </Row>
            );

        case 'exchange-with-icon':
            return (
                <Row id='exchange-with-icon-selector'
                    className='select exchange-with-icon-selector'
                    fit='all'
                    g='0'
                >
                    <Column>
                        <div id={id}
                            className={'select-overlap' + className}
                            onClick={() => setLastSelectId(id)}
                        >
                            <Row
                                a={'center'}
                                fill='width'
                                j='between'
                            >
                                <Row>
                                    <img src={getIcon(props.selected)} />
                                    <p><b>{props.selected}</b></p>
                                </Row>

                                {props.options.length > 1
                                    ? <MoveLeftArrowSvg className='selector-arrow' />
                                    : <></>
                                }
                            </Row>
                        </div>
                        <ul id='exchange-with-icon-selector-list'
                            className='select-list'
                            hidden
                        >
                            {
                                props.options.map((item, index) => {

                                    var isSelected = item === props.selected;

                                    if (!isSelected) {
                                        return (
                                            <li className={isSelected ? 'selected' : ''}
                                                key={'exchange-' + index}
                                                onClick={() => props.onSelect(item)}
                                            ><Row>
                                                    <img src={getIcon(item)} />
                                                    <p><b>{item}</b></p>
                                                </Row>
                                            </li>
                                        );
                                    }
                                })
                            }
                        </ul>
                    </Column>
                </Row>);

        case 'language':
            return (
                <Column className='select lang-selector' id='lang-selector' >
                    <div
                        className={'select-overlap' + className}
                        id={id}
                        onClick={() => setLastSelectId(id)}
                    >
                        <Row a={'center'} fill='width' j='between'>
                            <p><b>{props.selected}</b></p>
                            <MoveLeftArrowSvg className='selector-arrow' />
                        </Row>
                    </div>
                    <ul className='select-list' hidden>
                        {
                            props.options.map(([key, value], index) => {

                                var isSelected = value.icon === props.selected;

                                return (
                                    <li className={isSelected ? 'selected' : ''}
                                        key={'lang-' + index}
                                        onClick={() => props.onSelect(key)}
                                    >
                                        {value.icon} {value.label}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </Column>
            );

        case 'order-type':
            return (
                <Column className='select order-type-selector' id='order-type-selector' >
                    <div
                        className={'select-overlap' + className}
                        id={id}
                        onClick={() => setLastSelectId(id)}
                    >
                        <Row a={'center'} fill='width' j='between'>
                            <p><b>{props.selected}</b></p>
                            <MoveLeftArrowSvg className='selector-arrow' />
                        </Row>
                    </div>
                    <ul className='select-list' id='order-type-selector-list' hidden>
                        {
                            props.options.map((item, index) => {

                                var isSelected = item === props.selected;

                                if (!isSelected) {
                                    return (
                                        <li className={isSelected ? 'selected' : ''}
                                            key={'order-type-' + index}
                                            onClick={() => props.onSelect(item)}
                                        >{item}</li>
                                    );
                                }
                            })
                        }
                    </ul>
                </Column>
            );

        default:

            return (
                <Column>
                    <div id={id}
                        className={'select-overlap' + className}
                        onClick={() => setLastSelectId(id)}
                    >
                        <Row
                            a={'center'}
                            fill='width'
                            j='between'
                        >
                            <p><b>{props.selected}</b></p>

                            <MoveLeftArrowSvg className='selector-arrow' />
                        </Row>
                    </div>
                    <ul id={props.variation + '-selector-list'}
                        className='select-list'
                        hidden
                    >
                        {
                            props.options.map((item, index) => {

                                var isSelected = item === props.selected;

                                if (!isSelected) {
                                    return (
                                        <li className={isSelected ? 'selected' : ''}
                                            key={props.variation + '-' + index}
                                            onClick={() => props.onSelect(item)}
                                        >{item}</li>
                                    );
                                }
                            })
                        }
                    </ul>
                </Column >
            );
    }
};


Select.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    showInfo: PropTypes.bool,
    infoLabel: PropTypes.string,
    infoText: PropTypes.string,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    selected: PropTypes.string,
    variation: PropTypes.string
};


export default Select;
