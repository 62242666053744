import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// React-i18next
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';

import {
    Column, Link, List, Panel, Row, Title
} from 'components/imports';

import { LinkSvg } from 'icons/imports';


const ArticlesPanel = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const linkIcon = <LinkSvg className='icon-svg' />;

    return (
        <Panel id='last-news'
            fill='all'
        >
            <Row
                fill='width'
                j='between'
            >
                <Title variation='tertiary' txt={t('last-news')} />

                <Link
                    id='go-to-news-page'
                    onClick={() => {
                        let result = confirm(t('confirm-redirect-to-news'));

                        if (!result) { return; }
                        navigate('/news');
                    }}
                    variation='primary redirect'
                >
                    <p>{t('see-more-news')}</p>&nbsp;&nbsp;

                    <span>{linkIcon}</span>
                </Link>
            </Row>

            {props.loadingNews ? (
                <div id='last-articles-skeleton'>
                    {[0, 1, 2, 3].map(i =>
                        <Column
                            fill='width'
                            key={i}
                        >
                            <Skeleton
                                className='skeleton'
                                height={'1rem'}
                                width={'12rem'}
                            />

                            <Skeleton
                                className='skeleton'
                                height={'1rem'}
                                style={{ marginBottom: '1rem' }}
                                width={'10rem'}
                            />

                            <Row
                                fill='width'
                                j='between'
                            >
                                <Skeleton
                                    className='skeleton'
                                    height={'1rem'}
                                    style={{ marginBottom: '2rem' }}
                                    width={'5rem'}
                                />

                                <Skeleton
                                    className='skeleton'
                                    circle
                                    height={'2rem'}
                                    style={{ marginBottom: '2rem', marginRight: '2rem' }}
                                    width={'2rem'}
                                />
                            </Row>
                        </Column>
                    )}
                </div>
            ) : (
                <List id='news-list'
                    items={props.news}
                    lang={props.appLang}
                    variation='news-articles'
                />
            )}
        </Panel >
    );
};


ArticlesPanel.propTypes = {
    appLang: PropTypes.string,
    loadingNews: PropTypes.bool,
    news: PropTypes.array
};

export default ArticlesPanel;