/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

// JQuery
import $ from 'jquery';

// MomentJS
/* Useful Documentation links:
https://www.npmjs.com/package/react-moment#props
https://momentjs.com/ */
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';

// React Router
import { useNavigate, useLocation } from 'react-router-dom';

// React Translations
import i18n from 'locales/i18n';

// Components
import { MessagePopup, Header, Layout, Loading, Panel, Page } from 'components/imports'; // Layout
import { Bar, Column, Input, Row, Select, SwitchableIcon, Title } from 'components/imports'; // Elements

// Icons
import { CloseImg, ConfirmImg, LoadingImg, MoonSvg, SunSvg } from 'icons/imports'; // Icons

// API Endpoints
import { exchangesOhlcvGet, usersMeGet } from 'apis/imports';

import { pageLoaded } from 'utils/pages';
import { exchangeConstructor, getGlobalExchanges } from 'utils/exchanges';
import { checkAuthorization } from 'utils/token';

import {
    getLanguage, getTheme,
    setLanguage, setTheme
} from 'utils/cookies';

import { languages } from 'constants';

// Styles
import './Tools.css';


const Tools = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const pageId = 'tools';

    const [popUpDuration, setPopUpDuration] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    
    const [role, setRole] = useState('-');

    const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
    const [currentTheme, setCurrentTheme] = useState(getTheme());

    const [selectedExchange,] = useState('Binance');

    const defaultTimeframe = '5m';

    const [exportSymbol, setExportSymbol] = useState('BTC/USDT');
    const [exportTimeframe, setExportTimeframe] = useState();
    const [exportTimeframes,] = useState(['1m', '5m', '15m', '30m', '1h', '1d']);
    const [exportFromDate, setExportFromDate] = useState(moment().add(-1, 'months').format('YYYY-MM-DD HH:mm:ss Z'));
    const [exportToDate, setExportToDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss Z'));

    const [exportOhlcv, setExportOhlcv] = useState([]);

    const [exportProgressBar, setExportProgressBar] = useState(0);

    const [apiKey, setApiKey] = useState('hKD4yZvdxrvKUePCSB8I0HRSD6eu3k1LjYFyxBy85pQmXKZmIqsLBZwVt2t8zf4v');
    const [secret, setApiSecret] = useState('y3TOCHgrlE4bsawbols5jxWzYY2UmcOTBVmeQ8zB9FZ2dohjSwQkyQcz4alWk35i');

    const orderTypes = ['market', 'limit', 'STOP_LOSS', 'STOP_LOSS_LIMIT', 'TAKE_PROFIT', 'TAKE_PROFIT_LIMIT', 'STOP'];
    const [selectedBuyOrderType, setSelectedBuyOrderType] = useState('limit');
    const [selectedSellOrderType, setSelectedSellOrderType] = useState('limit');

    const handleChangeBuyOrderType = (type) => setSelectedBuyOrderType(type);
    const handleChangeSellOrderType = (type) => setSelectedSellOrderType(type);

    const [buyOrderSymbol, setBuyOrderSymbol] = useState('');
    const [sellOrderSymbol, setSellOrderSymbol] = useState('');

    var moonIcon = <MoonSvg className='icon-svg' />;
    var sunIcon = <SunSvg className='icon-svg'></SunSvg>;

    useEffect(() => {
        changeLanguage();
        changeTheme();
    }, []);


    useEffect(() => {

        if (location.pathname === `/${pageId}`) {

            $(document).scrollTop = 0;
            $('html, body').animate({ scrollTop: 0 }, 1);

            let $body = document.body.classList;
            $body.remove(...$body);
            $body.add(pageId + '-page');

            changeLanguage();
            changeTheme();

            $('body').css('overflow', 'hidden');
            //$('.loading-overlay').css('margin-top', 'var(--header-height-adjust-negative)');

            $('#user-fullname-input').hide();
            $('#user-fullname-label').hide();
            $('#mfa-code-message').hide();
            $('#mfa-code-img').hide();
            $('.message-popup-container').fadeOut();

            checkAuthorization(navigate);

            getUserMe();

            getGlobalExchanges({});

            pageLoaded(pageId);
        }

    }, [location.pathname]);


    useEffect(() => {

        if (role !== '-' && role !== 'ADMIN')
            navigate('/not-found');

    }, [role]);


    useEffect(() => {

        const $startBtn = $('#export-ohlcv-start-btn');
        const $step1 = $('#export-ohlcv-status-1');
        const $step2 = $('#export-ohlcv-status-2');

        if (exportOhlcv.length > 0 && exportProgressBar === 100) {

            $step1.children('img').prop('src', ConfirmImg);
            $step1.children('img').removeClass('spinning');

            $step2.removeClass('d-none');

            $step2.children('img').prop('src', ConfirmImg);
            $step2.children('img').removeClass('spinning');

            $startBtn.prop('disabled', false);

            // Formatar dados para CSV
            let csvContent = 'Date,Open,High,Low,Close,Volume\n';

            let ohlcv = exportOhlcv.flat();
            ohlcv.forEach(row => {
                let [timestamp, open, high, low, close, volume] = row;
                let date = moment(timestamp).utc().format('YYYY-MM-DD HH:mm:ss ');
                csvContent += `${date},${open},${high},${low},${close},${volume}\n`;
            });

            // Criar e baixar arquivo CSV
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${exportSymbol}_${exportTimeframe ?? defaultTimeframe}_${exportFromDate}_${exportToDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        else if (exportOhlcv.length === 0 && exportProgressBar === 100) {
            $startBtn.prop('disabled', true);
            $step2.children('img').removeClass('spinning');
            $step2.children('img').prop('src', CloseImg);

            $step2.children('p').text('No data to export.');
        }

        else if (exportProgressBar === 0 && exportOhlcv.length > 0) {
            setExportOhlcv([]);
        }

    }, [exportOhlcv, exportProgressBar]);


    const getUserMe = async () => {

        const result = await usersMeGet();

        if (result.isSuccess) {
            let { fullname, username, role } = result.response;

            $('#user-fullname').text(fullname);
            $('#user-email').text(username);
            if (role === 'ADMIN') $('#user-permission').show();

            setRole(role);

            pageLoaded(pageId);
        }
    };


    const hangleExportIntervalChange = (e) => {

        $('.export-ohlcv-inteval-bttn').removeClass('selected');

        const $button = $('#' + e.target.id);

        $button.addClass('selected');

        setExportTimeframe($button[0].innerText);
    };


    const changeLanguage = (newLang) => {
        if (!newLang) newLang = currentLanguage;

        setCurrentLanguage(newLang);
        setLanguage(newLang);

        i18n.changeLanguage(newLang);
    };


    const changeTheme = (newTheme) => {
        if (!newTheme) newTheme = currentTheme;

        setCurrentTheme(newTheme);
        setTheme(newTheme);

        document.body.classList.remove('bright');
        document.body.classList.remove('dark');
        document.body.classList.add(newTheme);
    };


    const startExport = async () => {

        setExportOhlcv([]);
        setExportProgressBar(0);

        const $startBtn = $('#export-ohlcv-start-btn');
        const $step0 = $('#export-ohlcv-status-0');
        const $step1 = $('#export-ohlcv-status-1');
        const $step2 = $('#export-ohlcv-status-2');

        $startBtn.prop('disabled', true);

        // Reset status
        $step0.children('img').prop('src', LoadingImg);
        $step0.children('p').text('Checking available range');
        if (!$step0.children('img').hasClass('spinning'))
            $step0.children('img').addClass('spinning');
        $step0.removeClass('d-none');

        setExportProgressBar(0);
        $step1.children('img').prop('src', LoadingImg);
        if (!$step1.children('img').hasClass('spinning'))
            $step1.children('img').addClass('spinning');
        $step1.addClass('d-none');

        $step2.children('img').prop('src', LoadingImg);
        if (!$step2.children('img').hasClass('spinning'))
            $step2.children('img').addClass('spinning');
        $step2.addClass('d-none');


        // Converter datas de string para timestamp
        const fromTimestamp = moment(exportFromDate, 'YYYY-MM-DD HH:mm:ss').valueOf();
        const toTimestamp = moment(exportToDate, 'YYYY-MM-DD HH:mm:ss').valueOf();

        const fetchOhlcv = async (since) => {

            const result = await exchangesOhlcvGet({
                exchange: selectedExchange.toLowerCase(),
                symbol: exportSymbol,
                timeframe: exportTimeframe ?? defaultTimeframe,
                since: since,
                until: toTimestamp,
            });

            $step0.children('img').removeClass('spinning');

            if (result.isSuccess) {
                $step0.children('img').prop('src', ConfirmImg);
                $step1.removeClass('d-none');

                let res = result.response;

                if ('items' in res) {
                    let exportOhlcvAux = exportOhlcv;
                    exportOhlcvAux.push(res.items);
                    setExportOhlcv(exportOhlcvAux);
                }

                // If returned items less than limit, then there is no more requests remaining
                if ('count' in res && 'limit' in res && res.count < res.limit) {
                    $step1.children('img').removeClass('spinning');
                    $step1.children('img').prop('src', ConfirmImg);
                    $step2.removeClass('d-none');
                    setExportProgressBar(100);
                }
                else if ('newest' in res) {

                    let newestTimestamp = res.newest;

                    setExportProgressBar(Math.round((newestTimestamp - fromTimestamp) * 100) / (toTimestamp - fromTimestamp));

                    newestTimestamp += 60000; // add 1 minute to avoid get the same item

                    fetchOhlcv(newestTimestamp);
                }
            }
            else {
                $startBtn.prop('disabled', false);
                $step0.children('p').text('Error on exporting OHLCV');
                $step0.children('img').prop('src', CloseImg);

                if (typeof result.detail === 'string') {
                    $step0.children('p').text(result.detail);
                }
            }
        };

        fetchOhlcv(fromTimestamp);
    };


    const fetchCurrentBuyPrice = async () => {
        try {
            const exchange = exchangeConstructor(selectedExchange);

            await exchange.loadMarkets();

            const setCurrentPrice = async () => {
                let ticker = await exchange.watchTickers([buyOrderSymbol]);
                setBuyOrderPrice(`${ticker[buyOrderSymbol]['last']}`);
            };

            setCurrentPrice();
            let interval = setInterval(async () => setCurrentPrice(), 10000);

            if (location.pathname !== `/${pageId}`) {
                clearInterval(interval);
            }
        }
        catch (err) { console.error(err); }
    };

    useEffect(() => {
        if (buyOrderSymbol.length > 0 && buyOrderSymbol.includes('/USDT')) {
            fetchCurrentBuyPrice();
        }
    }, [buyOrderSymbol]);

    const [buyOrderAmount, setBuyOrderAmount] = useState('');
    const [sellOrderAmount, setSellOrderAmount] = useState('');

    const [buyOrderPrice, setBuyOrderPrice] = useState('');
    const [sellOrderPrice, setSellOrderPrice] = useState('');

    const createBuyOrder = async () => {

        let exchange = exchangeConstructor(selectedExchange);

        let order = await exchange.createOrder(
            buyOrderSymbol,
            selectedBuyOrderType,
            'buy',
            buyOrderAmount,
            buyOrderPrice,
            { test: true }
        );

        order;
    };

    const createSellOrder = async () => {

    };

    return (
        <Page id={pageId} >
            <Loading id={'news-loading-overlay'} />

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                showHeader={true}
                showToolbar={true}
                dialogConfirm={false}
                maximizedScreen={false}
            >
                <Column fill='all'>

                    <Panel fit='height' id='export-ohlcv'>
                        <Row a='center' fill='width' j='between'>
                            <Title variation='tertiary' txt='Export OHLCV' />

                            <Column>
                                <label>Selected Exchange: <b>{selectedExchange}</b></label>
                            </Column>
                        </Row>

                        <hr />
                        <Row className='symbol-input' fill='width'>
                            <Column>
                                <label><b>Symbol</b></label>
                                <Input
                                    id='export-ohlcv-symbol-input'
                                    onChange={(e) => setExportSymbol(e.target.value)}
                                    placeholder={'BTC/USDT'}
                                    variation='text'
                                    value={exportSymbol}
                                />
                            </Column>

                            <Column>
                                <label><b>Timeframe</b></label>

                                <Row fill='width'>

                                    {
                                        exportTimeframes.map((timeframe, index) => {

                                            let isDefault = defaultTimeframe === timeframe && exportTimeframe == null;

                                            return (
                                                <button
                                                    className={'secondary export-ohlcv-inteval-bttn' + (isDefault ? ' selected' : '')}
                                                    id={'export-ohlcv-inteval-input-' + index}
                                                    key={index}
                                                    onClick={hangleExportIntervalChange}
                                                >{timeframe}</button>
                                            );
                                        })
                                    }
                                </Row>
                            </Column>
                        </Row>

                        <hr />
                        <Row fill='width'>
                            <Column fill='width'>
                                <label><b>From</b></label>
                                <Input
                                    id='export-ohlcv-from-input'
                                    onChange={(e) => {

                                        const ReGex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]) ([+-])(0[0-9]|1[0-3]):(00|30)$/;
                                        let value = e.target.value;

                                        if (ReGex.test(value)) $('#' + e.target.id).removeClass('required');
                                        else $('#' + e.target.id).addClass('required');

                                        setExportFromDate(value);
                                    }}
                                    placeholder={'YYYY-MM-DD HH:mm:ss'}
                                    variation='text'
                                    value={exportFromDate}
                                />
                            </Column>

                            <Column fill='width'>
                                <label><b>To</b></label>
                                <Input
                                    id='export-ohlcv-to-input'
                                    onChange={(e) => {
                                        const ReGex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]) ([+-])(0[0-9]|1[0-3]):(00|30)$/;
                                        let value = e.target.value;

                                        if (ReGex.test(value)) $('#' + e.target.id).removeClass('required');
                                        else $('#' + e.target.id).addClass('required');

                                        setExportToDate(e.target.value);
                                    }}
                                    placeholder={'YYYY-MM-DD HH:mm:ss'}
                                    variation='text'
                                    value={exportToDate}
                                />
                            </Column>

                            <Column>
                                <label className='no-margin'><b>Export</b></label>
                                <button
                                    className='primary'
                                    id='export-ohlcv-start-btn'
                                    onClick={() => {

                                        let validFrom = !$('#export-ohlcv-from-input').hasClass('required'),
                                            validTo = !$('#export-ohlcv-to-input').hasClass('required');

                                        if (validFrom && validTo) startExport();
                                        else alert('Please enter valid dates');
                                    }}>
                                    START
                                </button>
                            </Column>
                        </Row>

                        <hr />

                        <label id='export-ohlcv-status-label' className='d-none'><b>Status</b></label>
                        <Row
                            a='center'
                            className='export-symbol-ohlcv-status d-none'
                            id='export-ohlcv-status-0'
                            fill='width'
                        >
                            <img className='spinning' src={LoadingImg} />
                            &nbsp;&nbsp;
                            <p>Checking available range</p>
                        </Row>

                        <Row
                            a='center'
                            className='export-symbol-ohlcv-status d-none'
                            id='export-ohlcv-status-1'
                            fill='width'
                        >
                            <img className='spinning' src={LoadingImg} />

                            &nbsp;&nbsp;
                            <Row fill='width'>
                                <p>Fetching OHLC data</p>
                            </Row>

                            <Bar progress={exportProgressBar} />
                        </Row>

                        <Row
                            a='center'
                            className='export-symbol-ohlcv-status d-none'
                            id='export-ohlcv-status-2'
                            fill='width'
                        >
                            <img className='spinning' src={LoadingImg} />
                            &nbsp;&nbsp;
                            <p>Exporting file</p>
                        </Row>
                    </Panel>


                    <Row fill='width'>
                        <Panel id='exchange-crendecials' fill='width' fit='height' >
                            <Title variation='tertiary' txt='Exchange Credentials' />

                            <Row fill='width'>

                                <Column fit='all'>
                                    <label><b>API Key</b></label>
                                    <Input
                                        id='api-key-input'
                                        onChange={(e) => setApiKey(e.target.value)}
                                        placeholder={selectedExchange + ' API Key'}
                                        variation='text'
                                        value={apiKey}
                                    />
                                </Column>

                                <Column fit='all'>
                                    <label><b>Secret</b></label>

                                    <Input
                                        id='api-secret-input'
                                        onChange={(e) => setApiSecret(e.target.value)}
                                        placeholder={selectedExchange + ' Secret'}
                                        variation='text'
                                        value={secret}
                                    />
                                </Column>
                            </Row>

                        </Panel>
                    </Row>

                    <Row fill='width'>
                        <Panel id='buy-test' fit='height'>
                            <Title variation='tertiary' txt='Buy Test' />

                            <Row fill='width'>
                                <Column>
                                    <label><b>Symbol</b></label>

                                    <Input
                                        id='buy-order-symbol-input'
                                        onChange={(e) => setBuyOrderSymbol(e.target.value)}
                                        placeholder={'BTC/USDT'}
                                        variation='text'
                                        value={buyOrderSymbol}
                                    />
                                </Column>

                                <Column>
                                    <label><b>type</b></label>

                                    <Select
                                        className='order-type-dd'
                                        id='choose-buy-order-type'
                                        onSelect={handleChangeBuyOrderType}
                                        options={orderTypes}
                                        selected={selectedBuyOrderType}
                                        variation='order-type'
                                    />
                                </Column>
                            </Row>

                            <Row fill='width'>
                                <Column>
                                    <label><b>Amount</b></label>

                                    <Input
                                        id='buy-order-amount-input'
                                        onChange={(e) => setBuyOrderAmount(e.target.value)}
                                        placeholder={'0'}
                                        variation='text'
                                        value={buyOrderAmount}
                                    />
                                </Column>

                                <Column>
                                    <label><b>price</b></label>

                                    <Input
                                        id='buy-order-price-input'
                                        onChange={(e) => setBuyOrderPrice(e.target.value)}
                                        placeholder={'0'}
                                        variation='text'
                                        value={buyOrderPrice}
                                    />
                                </Column>
                            </Row>

                            <button className='primary fill-width' onClick={() => createBuyOrder()}>BUY</button>
                        </Panel>

                        <Panel id='sell-test' fit='height'>
                            <Title variation='tertiary' txt='Sell Test' />

                            <Row fill='width'>
                                <Column>
                                    <label><b>Symbol</b></label>

                                    <Input
                                        id='sell-order-symbol-input'
                                        onChange={(e) => setSellOrderSymbol(e.target.value)}
                                        placeholder={'BTC/USDT'}
                                        variation='text'
                                        value={sellOrderSymbol}
                                    />
                                </Column>

                                <Column>
                                    <label><b>type</b></label>

                                    <Select
                                        className='order-type-dd'
                                        id='choose-sell-order-type'
                                        onClick
                                        onSelect={handleChangeSellOrderType}
                                        options={orderTypes}
                                        selected={selectedSellOrderType}
                                        variation='order-type'
                                    />
                                </Column>
                            </Row>

                            <Row fill='width'>
                                <Column>
                                    <label><b>Amount</b></label>

                                    <Input
                                        id='sell-order-amount-input'
                                        onChange={(e) => setSellOrderAmount(e.target.value)}
                                        placeholder={'0'}
                                        variation='text'
                                        value={sellOrderAmount}
                                    />
                                </Column>

                                <Column>
                                    <label><b>price</b></label>

                                    <Input
                                        id='sell-order-price-input'
                                        onChange={(e) => setSellOrderPrice(e.target.value)}
                                        placeholder={'0'}
                                        variation='text'
                                        value={sellOrderPrice}
                                    />
                                </Column>
                            </Row>

                            <button className='primary fill-width' onClick={() => createSellOrder()}>SELL</button>
                        </Panel>
                    </Row>
                </Column>
            </Layout>
        </Page>
    );
};

export default Tools;
