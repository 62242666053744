/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

// Ant Design Imports
import {
    Card as AntdCard,
    Col as AntdCol,
    Row as AntdRow,
    Table as AntdTable,
    Select as AntdSelect,
    Avatar as AntdAvatar,
    Typography as AntdTypography,
    Skeleton as AntdSkeleton
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

// Custom Components and Utilities
import { Title, NoData } from 'components/imports';
import { getIcon } from 'icons/imports';

// Ant Design Select Components
const { Option } = AntdSelect;
const { Text } = AntdTypography;

const OrdersHistoryCard = () => {

    const [loading, setLoading] = useState(true);
    const [filteredDataOrders, setFilteredDataOrders] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState([]);
    const [selectedSide, setSelectedSide] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState([]);
    const [selectedBaseSymbol, setSelectedBaseSymbol] = useState([]);
    const [selectedQuoteSymbol, setSelectedQuoteSymbol] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    // Mock data for demonstration
    const data = [
        {
            author: 'ATS',
            exchange: 'binance',
            time: '07-18 00:10:24',
            pair: 'BTC/USDT',
            type: 'Limit',
            side: 'Buy',
            averagePrice: 64230.66,
            executed: 0.001,
            amount: 0.001,
            total: 64.73,
            tpSl: '--',
            status: 'Filled',
        },
        {
            author: 'ATS',
            exchange: 'binance',
            time: '07-18 00:00:13',
            pair: 'BTC/USDT',
            type: 'Market',
            side: 'Sell',
            averagePrice: 64730.25,
            executed: 0.001,
            amount: 0.001,
            total: 64.2306,
            tpSl: '--',
            status: 'Filled',
        }
    ];

    const handleFilterChange = () => {
        let filtered = data;

        if (selectedAuthor.length) {
            filtered = filtered.filter(item => selectedAuthor.includes(item.author));
        }
        if (selectedSide.length) {
            filtered = filtered.filter(item => selectedSide.includes(item.side));
        }
        if (selectedExchange.length) {
            filtered = filtered.filter(item => selectedExchange.includes(item.exchange));
        }
        if (selectedBaseSymbol.length) {
            filtered = filtered.filter(item => selectedBaseSymbol.includes(item.baseSymbol));
        }
        if (selectedQuoteSymbol.length) {
            filtered = filtered.filter(item => selectedQuoteSymbol.includes(item.quoteSymbol));
        }
        if (selectedStatus.length) {
            filtered = filtered.filter(item => selectedStatus.includes(item.status));
        }

        setFilteredDataOrders(filtered);
    };

    useEffect(() => {
        handleFilterChange();
    }, [selectedAuthor, selectedSide, selectedExchange, selectedBaseSymbol, selectedQuoteSymbol, selectedStatus]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    const columns = [
        { title: 'Author', dataIndex: 'author', key: 'author' },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            key: 'exchange',
            align: 'center',
            render: exchange => <AntdAvatar src={getIcon(exchange)} alt={exchange} />,
        },
        { title: 'Time', dataIndex: 'time', key: 'time' },
        { title: 'Pair', dataIndex: 'pair', key: 'pair' },
        { title: 'Type', dataIndex: 'type', key: 'type' },
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
            align: 'center',
            render: side => <span style={{ color: side === 'Buy' ? '#52C41A' : '#FF4D4F' }}>{side}</span>,
        },
        { title: 'Average Price', dataIndex: 'averagePrice', key: 'averagePrice' },
        { title: 'Executed', dataIndex: 'executed', key: 'executed' },
        { title: 'Amount', dataIndex: 'amount', key: 'amount' },
        { title: 'Total', dataIndex: 'total', key: 'total' },
        { title: 'TP/SL', dataIndex: 'tpSl', key: 'tpSl' },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => <span style={{ color: status === 'Filled' ? '#52c41a' : '#ff4d4f' }}>{status}</span>,
        },
    ];

    return (
        <AntdCard style={{ height: '52vh' }}>
            <AntdRow>
                <AntdCol span={12}>
                    <Title variation='tertiary' txt='Orders History' />
                </AntdCol>
            </AntdRow>

            <AntdRow>
                <AntdCol span={24}>
                    {loading ? (
                        <AntdRow justify="end" style={{ marginTop: '8px', marginLeft: '0px', marginRight: '0px' }}>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <AntdCol span={4} key={index} style={{ marginBottom: '16px' }}>
                                    <AntdSkeleton.Input active style={{ width: '13rem', height: '4rem' }} />
                                </AntdCol>
                            ))}
                        </AntdRow>
                    ) : (
                        <AntdRow gutter={[16, 0]} justify="end" style={{ marginTop: '8px', marginLeft: '0px', marginRight: '0px' }}>
                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Author</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Author"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedAuthor(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="ATS">ATS</Option>
                                    <Option value="User">User</Option>
                                </AntdSelect>
                            </AntdCol>

                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Side</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Side"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedSide(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="Buy">Buy</Option>
                                    <Option value="Sell">Sell</Option>
                                </AntdSelect>
                            </AntdCol>

                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Exchange</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Exchange"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedExchange(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="binance">
                                        <AntdAvatar src={getIcon('Binance')} /> Binance
                                    </Option>
                                </AntdSelect>
                            </AntdCol>

                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Base Symbol</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Base Symbol"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedBaseSymbol(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="Bitcoin">
                                        <AntdAvatar src="btc-icon-url" /> Bitcoin
                                    </Option>
                                </AntdSelect>
                            </AntdCol>

                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Quote Symbol</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Quote Symbol"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedQuoteSymbol(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="All symbols">All symbols</Option>
                                    <Option value="USDT">USDT</Option>
                                </AntdSelect>
                            </AntdCol>

                            <AntdCol span={4} style={{ marginBottom: '16px' }}>
                                <Text strong>Status</Text>
                                <AntdSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Status"
                                    style={{ width: '100%' }}
                                    onChange={value => setSelectedStatus(value)}
                                    suffixIcon={<DownOutlined />}
                                >
                                    <Option value="Filled">Filled</Option>
                                    <Option value="Canceled">Canceled</Option>
                                </AntdSelect>
                            </AntdCol>
                        </AntdRow>
                    )}
                </AntdCol>

                <AntdCol span={24}>
                    {loading ? (
                        <AntdSkeleton.Input active style={{ width: '82.4rem', height: '21rem' }} />
                    ) : filteredDataOrders.length === 0 ? (
                        <NoData text="No orders found" />
                    ) : (
                        <AntdTable
                            columns={columns}
                            dataSource={filteredDataOrders}
                            pagination={false}
                            rowKey={record => `${record.time}-${record.pair}-${record.type}-${record.side}`}
                        />
                    )}
                </AntdCol>
            </AntdRow>
        </AntdCard>
    );
};

export default OrdersHistoryCard;
