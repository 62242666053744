/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';

import { Column, Row } from 'components/imports';
import { Title } from 'components/imports';


const SignalsList = ({ items }) => {

    return (

        <Column
            className='signals-list'
            fill='width'
            fit='height'
        >
            {items.map((item) => {

                let { trade } = item;

                return (
                    <Row
                        a='center'
                        className={'signal-item'}
                        fill='all'
                        g='0'
                        j='between'
                        key={item.signal_id}
                        p='pri'
                    >
                        <Column>
                            <Title variation="secondary" txt={new Date(trade.timestamp * 1000).toLocaleString()} />
                            <p className='title'>Price: {trade.price}</p>
                        </Column>

                        <p className={`title trend-${trade.side === 'buy' ? 'up' : 'down'}-text`}>
                            {trade.side.toUpperCase()}
                        </p>
                    </Row>
                );
            })
            }
        </Column >
    );

};

export default SignalsList;
