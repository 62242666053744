import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // React Router
import PropTypes from 'prop-types';

import { Row, Column, Panel, Title, List } from 'components/imports'; // Ajuste o caminho conforme necessário
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Ícones e imagens
import { ExchangeConnectionSvg } from 'images/imports';

// APIs
import { signalsGet } from 'apis/imports';

// Utils
import { getCryptocurrencyName } from 'utils/cryptocurrencies';
import { loadIcons } from 'icons/imports';

// Tradução
import { useTranslation } from 'react-i18next';


const SignalsPanel = (props) => {
    const { t } = useTranslation();

    const { appLang, pageId } = props;

    const location = useLocation();

    const [signals, setSignals] = useState([]);
    const [loadingSignals, setLoadingSignals] = useState(true);

    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getSignals();
        }
    }, [location.pathname]);


    useEffect(() => {
        patchSignals(props.tickers[props.selectedExchange.toLowerCase()]);
    }, [props.tickers]);


    const getSignals = async () => {
        var result = await signalsGet();

        setLoadingSignals(false);

        if (!result.isSuccess) {
            setSignals([]);
            return;
        }

        var response = result.response.items;

        var iconNames = response.map(item => item.pair.split('/')[0].toLowerCase());
        iconNames.push('usdt');

        loadIcons(iconNames).then(imagesDict => {
            response = response.map(item => {
                var [base, quote] = item.pair.split('/');

                var baseName = getCryptocurrencyName(base);
                var quoteName = getCryptocurrencyName(quote);

                item.iconBase = imagesDict[base.toLowerCase()];
                item.iconQuote = imagesDict[quote.toLowerCase()];
                item.pairName = `${baseName} / ${quoteName}`;
                item.pairSymbol = item.pair.toUpperCase();
                item.pnl = item?.pnl_percentage;

                delete item.pair;

                return item;
            });

            setSignals(response);
        });
    };


    const patchSignals = (tickers) => {

        let signalsAux = [...signals];
        
        signalsAux.forEach(item => {
            const { side, pairSymbol, price } = item;
            if (side == 'buy' && tickers && pairSymbol in tickers)
                item.pnl = (tickers[pairSymbol].last - price) / price;
        });

        setSignals(signalsAux);
    };


    return (
        <Panel id='signals' fill='all' key='signals'>
            <Row fill='width' j='between'>
                <Title variation='tertiary' txt={t('last-signals')} />
            </Row>

            <hr />
            {loadingSignals
                ? (<Row
                    fill='width'
                    j='between'
                >
                    <Skeleton
                        className='skeleton'
                        count={5}
                        height={'2.5rem'}
                        style={{ marginBottom: '.75rem' }}
                        width={'10rem'}
                    />
                    <Skeleton
                        className='skeleton'
                        count={5}
                        height={'2.5rem'}
                        style={{ marginBottom: '.75rem' }}
                        width={'5rem'}
                    />
                    <Skeleton
                        className='skeleton'
                        count={5}
                        height={'2.5rem'}
                        style={{ marginBottom: '.75rem' }}
                        width={'5rem'}
                    />
                    <Skeleton
                        className='skeleton'
                        count={5}
                        height={'2.5rem'}
                        style={{ marginBottom: '.75rem' }}
                        width={'5rem'}
                    />
                    <Skeleton
                        className='skeleton'
                        count={5}
                        height={'2.5rem'}
                        style={{ marginBottom: '.75rem' }}
                        width={'5rem'}
                    />
                </Row>)
                : (signals.length === 0
                    ? (<Row
                        a='center'
                        fill='all'
                        j='center'
                    >
                        <Column
                            a='center'
                            fit='all'
                            j='center'
                        >
                            <img className='empty-img' src={ExchangeConnectionSvg} />

                            <p>{t('message-signals-empty')}</p>
                        </Column>
                    </Row>)
                    : (<List id='signals'
                        items={signals}
                        lang={appLang}
                        variation='signals'
                    />)
                )}
        </Panel>
    );
};


SignalsPanel.propTypes = {
    appLang: PropTypes.string,
    pageId: PropTypes.string,
    selectedExchange: PropTypes.string,
    tickers: PropTypes.object
};


export default SignalsPanel;
