import React from 'react';
import PropTypes from 'prop-types';

import './Page.css';

const Page = (props) => {

    return (
        <div className='page' id={ props.id }>
            { props.children }
        </div>
    );
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
};

export default Page;
