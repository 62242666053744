/* eslint-disable react/prop-types */
import React from 'react';

import { Bar, Column, Row, Title } from 'components/imports';


const ExecutionCard = ({ execution, isSelected, onClick }) => (
    <Column
        className={'execution-item ' + (isSelected ? 'selected' : '')}
        onClick={onClick}
        p='pri'
    >
        <p className='title'>{execution.executed_by}</p>

        <Title variation="secondary" txt={execution.backtest_id} />

        <Row
            fill='width'
        >
            <Bar
                progress={execution.progress}
                showInfo={false}
            />
            <Title variation="secondary" txt={`${execution.progress}%`} />
        </Row>
    </Column >
);

const ExecutionList = ({ executions, selectedExecution, onCardClick }) => {

    return (
        <Column
            fill='width'
            fit='height'
        >
            {executions.map((execution, index) => (
                <ExecutionCard
                    execution={execution}
                    isSelected={selectedExecution === index}
                    onClick={() => onCardClick(index)}
                    key={index}
                />
            ))
            }
        </Column>
    );
};

export default ExecutionList;
