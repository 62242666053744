/* eslint-disable react/prop-types */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */

// React and Core Libraries
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';

// Device Detection
import { BrowserView } from 'react-device-detect';

// Cookies, Tour, and i18n
import { useTour } from '@reactour/tour';
import i18n from 'locales/i18n';

// Ant Design Components
import { Row as AntdRow, Col as AntdCol, Segmented as AntdSegmented, Card as AntdCard } from 'antd';

// Custom Components
import {
    Loading,
    SwitchableIcon,
    Column,
    Layout,
    Page,
    Row,
    Header,
    MessagePopup,
    Select,
    Title,
} from 'components/imports';
import InfoCard from './components/InfoCard';
import NegotiationPairsCard from './components/NegotiationPairsCard';
import OrdersHistory from './components/OrdersHistory';
import OrderDetails from './components/OrderDetails';

// Icons and Images
import { MoonSvg, SunSvg } from 'icons/imports';

// Utilities
import { getLanguage, getTheme, setLanguage, setTheme } from 'utils/cookies';
import { pageLoaded } from 'utils/pages';
import { checkAuthorization } from 'utils/token';

// APIs
import { balancesMeGet, simulationBalancesMeGet, simulationBalancesMePost } from 'apis/imports';

// Constants
import { languages } from 'constants';

// Styles
import './Ats.css';

const Ats = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'ats';

    // State management
    const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
    const [currentTheme, setCurrentTheme] = useState(getTheme());
    
    const [popUpDuration, setPopUpDuration] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    
    const [simulationBalanceData, setSimulationBalanceData] = useState(null);
    const [environmentState, setEnvironmentState] = useState({
        currentLanguage: getLanguage(),
        currentTheme: getTheme(),
        segmentedValue: 'Default',
    });

    const { setIsOpen, setCurrentStep } = useTour();

    // Icons
    const moonIcon = <MoonSvg className="icon-svg" />;
    const sunIcon = <SunSvg className="icon-svg" />;

    // Event Handlers
    const handleSegmentChange = useCallback((value) => {
        setEnvironmentState((prevState) => ({
            ...prevState,
            segmentedValue: value,
        }));
    }, []);

    const fetchSimulationBalances = async () => {
        try {
            const result = await simulationBalancesMeGet();
            if (result.isSuccess) {
                setSimulationBalanceData(result.response);
            } else {
                console.error('Failed to fetch balances:', result);
            }
        } catch (error) {
            console.error('Error fetching balances:', error);
        }
    };

    const updateSimulationBalance = async (newBalance) => {
        try {
            const result = await simulationBalancesMePost(newBalance, 'USDT');
            if (result) {
                setSimulationBalanceData({
                    balance: {
                        value: newBalance,
                        currency: 'USDT',
                    }
                });
                setPopUpText(t('success-balance-edit'));
                setPopUpLevel('success');
                return true;
            } else {
                setPopUpText(t('error-balance-edit'));
                setPopUpLevel('error');
                return false;
            }
        } catch (error) {
            setPopUpText(t('error-balance-edit'));
            setPopUpLevel('error');
            return false;
        }
    };

    useEffect(() => {
        fetchSimulationBalances();
    }, []);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            fetchSimulationBalances();
        }
    }, [location.pathname]);


    return (
        <Page id={pageId}>
            <Loading id="ats-overlay" />

            <MessagePopup 
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings={'full'}
                showHeader={true}
                showToolbar={true}
                dialogConfirm={false}
                maximizedScreen={false}
            >
                <Column fill="width" m="0" p="0">
                    {/* Main Content Section */}
                    <AntdRow className="ats-content" gutter={[16, 16]} style={{ height: '100%', margin: 0, flex: 1 }}>
                        <AntdCol span={24}>
                            <AntdRow gutter={[16, 16]} style={{ height: '100%' }}>
                                <AntdCol span={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    {environmentState.segmentedValue === 'Simulation' && (
                                        <InfoCard
                                            title="Fictitious Balance"
                                            value={simulationBalanceData?.balance?.value ?? 0}
                                            valueUnit={simulationBalanceData?.balance?.currency ?? 'USDT'}
                                            editable={true}
                                            onEdit={updateSimulationBalance}
                                        // percentageChange={10}
                                        />
                                    )}

                                    <InfoCard title="Allocations" value={2000} valueUnit="USDT" />

                                    <NegotiationPairsCard
                                        mode={environmentState.segmentedValue}
                                    />
                                </AntdCol>

                                <AntdCol span={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <OrdersHistory />
                                </AntdCol>

                                <AntdCol span={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <OrderDetails />
                                </AntdCol>
                            </AntdRow>
                        </AntdCol>
                    </AntdRow>
                </Column>
            </Layout>
        </Page>
    );
};

export default Ats;
