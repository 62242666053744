import React from 'react';
import PropTypes from 'prop-types';

import './Badge.css';


const Badge = (props) => {

    const variation = props.variation ? ' ' + props.variation : ' primary';

    return (
        <div className={props.className + ' badge fit-width' + variation}>
            {props.children}
        </div>
    );
};


Badge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variation: PropTypes.string
};


export default Badge;
