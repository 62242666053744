/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

// Ant Design Imports
import { Card as AntdCard, Row as AntdRow, Col as AntdCol, Button, Typography as AntdTypography, Tooltip, Avatar } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HolderOutlined, CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// API Imports
import { atsModesPairsGet, atsModesPairsPatch, atsModesPairsDelete } from 'apis/imports';

// Custom Components
import AddPairModal from './AddPairModal';
import { Title } from 'components/imports';

// Icon Imports
import { getIcon, loadIcons } from 'icons/imports';

// Ant Design Typography
const { Text } = AntdTypography;

const NegotiationPairsCard = ({ mode }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedPair, setSelectedPair] = useState(null);
    const [exchangesData, setExchangesData] = useState([]);
    const [iconMap, setIconMap] = useState({});

    /**
     * Fetches the pairs based on the selected mode and sets the state with fetched data.
     */
    const fetchPairsByMode = async () => {
        try {
            const result = await atsModesPairsGet(mode);
            if (result.isSuccess) {
                const apiData = result.response;

                const allSymbols = [
                    ...new Set(apiData.flatMap(pair => [pair.base.symbol.toLowerCase(), pair.quote.symbol.toLowerCase()])),
                ];
                const loadedIcons = await loadIcons(allSymbols);
                setIconMap(loadedIcons);

                const groupedExchanges = apiData.reduce((acc, pair) => {
                    const exchangeName = pair.exchange_name;

                    if (!acc[exchangeName]) {
                        acc[exchangeName] = {
                            exchange: exchangeName,
                            totalAllocated: 1000,
                            pairs: [],
                        };
                    }

                    acc[exchangeName].pairs.push({
                        id: pair.pair_id.toString(),
                        exchange: pair.exchange_name,
                        symbolFrom: pair.base.symbol,
                        symbolTo: pair.quote.symbol,
                        allocations: {
                            from: `${pair.base.allocation}`,
                            to: `${pair.quote.allocation}`,
                        },
                        enabled: pair.enabled,
                        createOrder: pair.autoOrder,
                        baseType: pair.base.type,
                        baseHasEnough: pair.base.has_enough,
                        quoteType: pair.quote.type,
                        quoteHasEnough: pair.quote.has_enough,
                    });

                    return acc;
                }, {});

                setExchangesData(Object.values(groupedExchanges));
            } else {
                console.error('Failed to fetch pairs:', result);
            }
        } catch (error) {
            console.error('Error fetching pairs:', error);
        }
    };

    const showModal = () => {
        setIsEditMode(false);
        setIsModalOpen(true);
        setSelectedPair(null);
    };

    const showEditModal = (pair) => {
        setIsEditMode(true);
        setSelectedPair(pair);
        setIsModalOpen(true);
    };

    const handleCancel = () => setIsModalOpen(false);

    const handleAddPair = () => {
        setIsModalOpen(false);
    };

    /**
     * Reorders the pairs after a drag-and-drop action.
     */
    const reorderPairs = (pairs, startIndex, endIndex) => {
        const result = Array.from(pairs);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /**
     * Handles the drag end event and updates the pair order in the backend.
     */
    const handleDragEnd = async (result, exchangeIndex) => {
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        const newExchangesData = Array.from(exchangesData);
        const updatedPairs = reorderPairs(newExchangesData[exchangeIndex].pairs, sourceIndex, destinationIndex);
        const movedPair = updatedPairs[destinationIndex];

        newExchangesData[exchangeIndex].pairs = updatedPairs;
        setExchangesData(newExchangesData);

        try {
            await atsModesPairsPatch(mode, movedPair.id, { priority: destinationIndex });
            console.log(`Pair ${movedPair.id} updated with priority: ${destinationIndex}`);
        } catch (error) {
            console.error(`Error updating priority for pair ${movedPair.id}:`, error);
        }
    };

    // Handles deletion of a pair
    const handleDeletePair = async (pairId) => {
        try {
            const result = await atsModesPairsDelete(mode, pairId);
            if (result.isSuccess) {
                fetchPairsByMode();
            } else {
                console.log('Error deleting pair');
                fetchPairsByMode();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPairsByMode();
    }, []);

    return (
        <>
            <AntdCard style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <AntdRow align="middle">
                    <AntdCol span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Title variation="tertiary" txt="Negotiation Pairs" />
                    </AntdCol>

                    <AntdCol span={12} style={{ display: 'flex', jContent: 'flex-end', alignItems: 'center' }}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                            Add Pair
                        </Button>
                    </AntdCol>
                </AntdRow>

                <AntdRow style={{ marginTop: '12px', flex: '1', overflowY: 'auto' }}>
                    <AntdCol span={24}>
                        {exchangesData.map((exchange, exchangeIndex) => (
                            <div key={exchange.exchange} style={{ marginBottom: '24px' }}>
                                <AntdRow justify="space-between" align="middle">
                                    <AntdCol>
                                        <Avatar src={getIcon(exchange.exchange)} style={{ marginRight: '8px' }} />
                                        <Text strong>{exchange.exchange}</Text>
                                    </AntdCol>
                                    <AntdCol>
                                        <Text>Total Allocated {exchange.totalAllocated}</Text>
                                    </AntdCol>
                                </AntdRow>

                                <DragDropContext onDragEnd={(result) => handleDragEnd(result, exchangeIndex)}>
                                    <Droppable droppableId={`exchange-${exchangeIndex}`}>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {exchange.pairs.map((pair, index) => (
                                                    <Draggable key={pair.id} draggableId={pair.id} index={index}>
                                                        {(provided) => (
                                                            <AntdCard
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{ marginTop: '12px', ...provided.draggableProps.style }}
                                                            >
                                                                <AntdRow justify="space-between" align="middle">
                                                                    <AntdCol span={2}>
                                                                        <HolderOutlined style={{ fontSize: '24px', color: '#888' }} />
                                                                    </AntdCol>

                                                                    <AntdCol span={8} style={{ paddingLeft: '8px' }}>
                                                                        <AntdRow align="middle">
                                                                            <AntdCol span={10}>
                                                                                <Avatar src={iconMap[pair.symbolFrom.toLowerCase()]} alt={pair.symbolFrom} style={{ marginRight: '8px' }} />
                                                                            </AntdCol>
                                                                            <AntdCol span={14}>
                                                                                <Text strong>{pair.symbolFrom}</Text>
                                                                                <br />
                                                                                <Text type="secondary">Bitcoin</Text>
                                                                            </AntdCol>
                                                                        </AntdRow>
                                                                    </AntdCol>

                                                                    <AntdCol span={2}>
                                                                        <Text strong>/</Text>
                                                                    </AntdCol>

                                                                    <AntdCol span={8} style={{ paddingLeft: '8px' }}>
                                                                        <AntdRow align="middle">
                                                                            <AntdCol span={10}>
                                                                                <Avatar src={iconMap[pair.symbolTo.toLowerCase()]} alt={pair.symbolTo} style={{ marginRight: '8px' }} />
                                                                            </AntdCol>
                                                                            <AntdCol span={14}>
                                                                                <Text strong>{pair.symbolTo}</Text>
                                                                                <br />
                                                                                <Text type="secondary">Tether</Text>
                                                                            </AntdCol>
                                                                        </AntdRow>
                                                                    </AntdCol>

                                                                    <AntdCol span={4} style={{ display: 'flex', jContent: 'flex-end' }}>
                                                                        <Tooltip title="Edit Pair">
                                                                            <EditOutlined
                                                                                style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                                onClick={() => showEditModal(pair)}
                                                                            />
                                                                        </Tooltip>

                                                                        <Tooltip title="Delete Pair">
                                                                            <DeleteOutlined
                                                                                style={{ color: 'red', cursor: 'pointer' }}
                                                                                onClick={() => handleDeletePair(pair.id)}
                                                                            />
                                                                        </Tooltip>
                                                                    </AntdCol>
                                                                </AntdRow>

                                                                <AntdRow gutter={16} style={{ marginTop: '12px' }}>
                                                                    <AntdCol>
                                                                        {pair.enabled ? (
                                                                            <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                        ) : (
                                                                            <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                        )}
                                                                        <Text style={{ marginLeft: '8px' }}>Enabled</Text>
                                                                    </AntdCol>

                                                                    <AntdCol>
                                                                        {pair.createOrder ? (
                                                                            <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                        ) : (
                                                                            <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                        )}
                                                                        <Text style={{ marginLeft: '8px' }}>Create orders automatically</Text>
                                                                    </AntdCol>
                                                                </AntdRow>

                                                                <AntdRow style={{ marginTop: '12px' }}>
                                                                    <AntdCol span={24}>
                                                                        <Text strong>Allocations</Text>
                                                                    </AntdCol>
                                                                </AntdRow>

                                                                <AntdRow style={{ marginTop: '6px' }}>
                                                                    <AntdCol span={12}>
                                                                        <AntdRow>
                                                                            <AntdCol span={24}>
                                                                                <Text>Bitcoin: {pair.allocations.from} {pair.baseType === 'Percentage' ? '%' : ''}</Text>
                                                                            </AntdCol>

                                                                            <AntdCol span={24}>
                                                                                {pair.baseType === 'Balance' ? (
                                                                                    <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                                ) : (
                                                                                    <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                                )}
                                                                                <Text style={{ marginLeft: '8px' }}>Define static value</Text>
                                                                            </AntdCol>

                                                                            <AntdCol span={24}>
                                                                                {pair.baseHasEnough ? (
                                                                                    <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                                ) : (
                                                                                    <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                                )}
                                                                                <Text style={{ marginLeft: '8px' }}>Create order if have enough balance only</Text>
                                                                            </AntdCol>
                                                                        </AntdRow>
                                                                    </AntdCol>

                                                                    <AntdCol span={12}>
                                                                        <AntdRow>
                                                                            <AntdCol span={24}>
                                                                                <Text>Tether: {pair.allocations.from} {pair.baseType === 'Percentage' ? '%' : ''}</Text>
                                                                            </AntdCol>

                                                                            <AntdCol span={24}>
                                                                                {pair.quoteType === 'Balance' ? (
                                                                                    <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                                ) : (
                                                                                    <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                                )}
                                                                                <Text style={{ marginLeft: '8px' }}>Define static value</Text>
                                                                            </AntdCol>

                                                                            <AntdCol span={24}>
                                                                                {pair.quoteHasEnough ? (
                                                                                    <CheckCircleFilled style={{ color: '#BFBFBF' }} />
                                                                                ) : (
                                                                                    <CloseCircleOutlined style={{ color: '#BFBFBF' }} />
                                                                                )}
                                                                                <Text style={{ marginLeft: '8px' }}>Create order if have enough balance only</Text>
                                                                            </AntdCol>
                                                                        </AntdRow>
                                                                    </AntdCol>
                                                                </AntdRow>
                                                            </AntdCard>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        ))}
                    </AntdCol>
                </AntdRow>
            </AntdCard>

            <AddPairModal
                visible={isModalOpen}
                onCancel={handleCancel}
                onConfirm={handleAddPair}
                mode={mode}
                refreshPairs={fetchPairsByMode}
                pairToEdit={selectedPair}
                isEditMode={isEditMode}
            />
        </>
    );
};

export default NegotiationPairsCard;
