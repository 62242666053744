import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'components/imports';

import { getIcon } from 'icons/imports';

import './ExchangesPill.css';


const ExchangesPill = (props) => {

    return (
        <Row
            className='exchanges-pill'
            g='0'
        >
            {
                props.exchanges.slice(0, 3).map((exchange) => (
                    <img
                        alt={exchange + ' logo'}
                        className='asset-icon mini-icon'
                        key={exchange}
                        src={getIcon(exchange)}
                        title={exchange}
                    />
                ))
            }
            {
                props.exchanges.length > 3 && (
                    <p>+{props.exchanges.length - 3}</p>
                )
            }
        </Row>
    );
};


ExchangesPill.propTypes = {
    exchanges: PropTypes.array
};


export default ExchangesPill;