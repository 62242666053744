/* eslint-disable @typescript-eslint/no-unused-vars */

import ccxt from 'ccxt';

import { appEnv, exchanges as exchangesConstant, userService } from 'constants';


export const getGlobalExchanges = ({asObject = false}) => {

    let exchanges = sessionStorage.getItem('exchanges');

    if (!exchanges) {
        // let res = $.get(`${userService.hosts[appEnv]}${userService.api.exchanges.get.path}`);
        // exchanges = res.responseJSON;
        // sessionStorage.setItem('exchanges', res.responseText);

        exchanges = exchangesConstant;

        sessionStorage.setItem('exchanges', JSON.stringify(exchanges));
    }
    else exchanges = JSON.parse(exchanges);

    if (asObject) return exchanges;
    return Object.values(exchanges);
};


export const exchangeConstructor = (exchange, type = 'basic') => {

    const exchanges = getGlobalExchanges({asObject: true});

    const { hosts, version } = userService;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/proxy?url=`;

    // Check if exchange is a string
    if (typeof exchange !== 'string') {
        console.error('Exchange must be a string');
        return;
    }

    exchange = exchange.toLowerCase();

    // Check if exchange exists
    if (!exchanges[exchange]) {
        console.error(`Exchange "${exchange}" is not supported`);
        return;
    }

    try {
        var exchangeObject;

        if (type == 'pro') exchangeObject = new ccxt.pro[exchange]({timeout: 30000});
        else exchangeObject = new ccxt[exchange]({timeout: 30000});

        //if (['kucoin'].includes(exchange))
        //    exchangeObject.proxy = url;

        return exchangeObject;
    }
    catch (e) {
        console.error(e);
    }
};
