/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

// Ant Design Imports
import { Tooltip } from 'antd';

// Custom Components and Utilities
import { Column, Row, List, Panel, SwitchableIcon, Title } from 'components/imports';

import { ExchangeConnectionSvg } from 'images/imports';

import { getIcon } from 'icons/imports';
import { HideSvg, InfoSvg, ShowSvg } from 'icons/imports';
import { currencyFormatter } from 'utils/formatters';

// Skeleton
import Skeleton from 'react-loading-skeleton';

import { getCurrency, getSelectedExchange, } from 'utils/cookies';

import { getGlobalExchanges } from 'utils/exchanges';


const BalancesPanel = (props) => {

    const { t } = useTranslation();

    const [selectedExchange,] = useState(getSelectedExchange().toLowerCase());
    const [currency,] = useState(getCurrency());
    const [exchanges,] = useState(getGlobalExchanges({ asObject: true }));

    const [userAssets, setUserAssets] = useState({});
    const [userDataAux, setUserDataAux] = useState({});

    const [showWallet, setShowWallet] = useState(true);
    const [showWalletInfo, setShowWalletInfo] = useState(false);

    const [hideSmallPositions, setHideSmallPositions] = useState(false);

    const handleToggleWallet = () => {
        setShowWallet(!showWallet);
    };

    const hideIcon = <HideSvg className='icon-svg' />;
    const infoIcon = <InfoSvg className='icon-svg' />;
    const showIcon = <ShowSvg className='icon-svg' />;


    useEffect(() => {

        let data = JSON.parse(JSON.stringify(props.portfolioData));
        let dataAux = { ...userDataAux };
        let tickers = props.tickers;

        if (!data || !data.length || !tickers) return;

        data.forEach(asset => {
            const pair = `${asset.symbol}/${currency}`;

            if (asset.symbol == currency)
                asset.selExcLast = 1;
            else if (selectedExchange in tickers && pair in tickers[selectedExchange])
                asset.selExcLast = tickers[selectedExchange][pair].last;
            else
                asset.selExcLast = dataAux[asset.symbol]?.selExcLast || null;

            if (asset.selExcLast) {

                asset.perExchange = Object.entries(asset.perExchange).reduce((acc, [exchange, total]) => {

                    acc[exchange] = {
                        balance: 0,
                        last: null,
                        pnl: null,
                        total
                    };

                    if (asset.symbol == currency) {
                        acc[exchange].last = 1;
                        acc[exchange].balance = total;
                        acc[exchange].total = total;
                    }
                    else if (exchange in tickers && pair in tickers[exchange]) {
                        let { last, pnl } = tickers[exchange][pair];
                        let balance = last * total;

                        acc[exchange].last = last;
                        acc[exchange].pnl = pnl;
                        acc[exchange].balance = balance;
                    }

                    return acc;
                }, {});

                dataAux[asset.symbol] = asset;
            }
        });

        setUserDataAux(dataAux);

        let dataList = Object.values(dataAux);
        let totalBalances = { total: 0, perExchange: {} };

        dataList.forEach(asset => {
            asset.balance = asset.selExcLast * asset.total;
            totalBalances.total += asset.balance;

            Object.entries(asset.perExchange).forEach(([exchange, values]) => {
                if (!(exchange in totalBalances.perExchange))
                    totalBalances.perExchange[exchange] = 0;
                totalBalances.perExchange[exchange] += values.balance;
            });
        });

        data = dataList.sort((a, b) => b.balance - a.balance);

        setUserAssets({
            totalBalances,
            data
        });

    }, [props.portfolioData, props.tickers]);


    const adaptPerExchangeBalance = (obj) => {
        let list = Object.entries(obj);
        list.sort((a, b) => b[1] - a[1]);
        return list;
    };


    return (
        <>
            <Panel id='balances'
                fill='width'
                fit='height'
            >
                <Row
                    className='title'
                    fill='width'
                    fit='height'
                    j='between'
                >
                    <Row
                        a='center'
                        fit='width'
                        j='start'
                    >
                        <Title variation='tertiary' txt={t('balance.p')} />

                        <Tooltip
                            color='#1D96EE'
                            open={showWalletInfo}
                            title={
                                <>
                                    <label><b>{t('your-balance.p')}</b></label>

                                    <p>{t('balances-info')}</p>
                                </>
                            }
                        >
                            <Row id='icon-container' onClick={() => setShowWalletInfo(!showWalletInfo)}>
                                {infoIcon}
                            </Row>
                        </Tooltip>
                    </Row>

                    <Row
                        a='center'
                        fit='width'
                        j='end'
                    >
                        {/* <Button
                        variation='secondary'
                    >{'>1$'}</Button> */}

                        <SwitchableIcon
                            onImage={showIcon}
                            onToggle={handleToggleWallet}
                            offImage={hideIcon}
                            isOn={showWallet}
                        />
                    </Row>
                </Row>

                <hr />
                <Row fill='width'>
                    <p><b>{t('total')} </b></p>

                    {props.loadingAssets ? (
                        <Skeleton
                            className='skeleton'
                            height={'1.5rem'}
                            width={'5rem'}
                        />
                    ) : <>
                        <p className={'fill-width money-value pri ' + (showWallet ? 'occult' : '')}>
                            ≈ <b>{currencyFormatter(userAssets?.totalBalances?.total || 0)}</b>
                        </p>
                        <p className='fill-width money-currency'>
                            {currency}
                        </p>
                    </>
                    }
                </Row>

                {props.loadingAssets ? (
                    <Column>
                        <Row>
                            <Skeleton
                                circle
                                className='skeleton'
                                height={'1.75rem'}
                                width={'1.75rem'}
                            />
                            <Skeleton
                                className='skeleton'
                                height={'1.5rem'}
                                width={'10rem'}
                            />
                        </Row>
                        <Row>
                            <Skeleton
                                circle
                                className='skeleton'
                                height={'1.75rem'}
                                width={'1.75rem'}
                            />
                            <Skeleton
                                className='skeleton'
                                height={'1.5rem'}
                                width={'10rem'}
                            />
                        </Row>
                    </Column>
                ) : (
                    <Column>
                        {userAssets?.totalBalances?.perExchange && (
                            <>
                                {adaptPerExchangeBalance(userAssets.totalBalances.perExchange).map(([exchange, total], index) => (
                                    <Row key={index}
                                        fill='width'
                                    >
                                        <Row>
                                            <img className='asset-icon' src={getIcon(exchange)} title={exchange} alt={exchange + ' logo'} />

                                            <p>{exchange in exchanges ? exchanges[exchange] : exchange}</p>
                                        </Row>

                                        <p className={'fill-width money-value sec ' + (showWallet ? 'occult' : '')}>
                                            ≈ <b>{currencyFormatter(total)}</b>
                                        </p>

                                        <p className='fill-width money-currency'>
                                            {currency}
                                        </p>
                                    </Row>
                                ))}
                            </>
                        ) || (<></>)}
                    </Column>
                )}
            </Panel>
            <Panel>
                <Row
                    fill='width'
                    j='between'
                >
                    <Title variation='tertiary' txt={t('position.p')} />

                    <label className='row g-0'>
                        <span className='input-checkbox-container'>
                            <input
                                checked={hideSmallPositions}
                                name='in-wallet-check'
                                id='in-wallet-check'
                                onChange={() => setHideSmallPositions(!hideSmallPositions)}
                                type='checkbox'
                            />
                            <span className='checkmark'></span>
                        </span>

                        <p>{t('hide-small-positions').replace('{currency}', currency)}</p>
                    </label>
                </Row>

                {props.loadingAssets ? (
                    <div id='balances-assets-skeleton'>
                        {
                            [0, 1, 2, 3, 4].map(i => (
                                <Row
                                    fill='width'
                                    j='between'
                                    key={i}
                                >
                                    <Row>
                                        <Skeleton
                                            className='skeleton'
                                            circle
                                            height={'35px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'35px'}
                                        />

                                        <Skeleton
                                            className='skeleton'
                                            height={'35px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'5rem'}
                                        />
                                    </Row>

                                    <Column
                                        a='end'
                                        g='0'
                                    >
                                        <Skeleton
                                            className='skeleton'
                                            height={'15px'}
                                            width={'5rem'}
                                        />

                                        <Skeleton
                                            className='skeleton'
                                            height={'15px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'6rem'}
                                        />
                                    </Column>
                                </Row>
                            ))
                        }
                    </div>
                ) : (!userAssets?.data?.length
                    ? <Column
                        a='center'
                        fill='width'
                        h='24'
                        j='center'
                    >
                        <Column
                            a='center'
                            fit='all'
                        >
                            <img className='empty-img' src={ExchangeConnectionSvg} />

                            <p style={{ textAlign: 'center' }}>
                                {t('message-signals-empty')}
                            </p>
                        </Column>
                    </Column>
                    : <List id='assets-list'
                        hideValues={showWallet}
                        hideSmallPositions={hideSmallPositions}
                        items={userAssets.data}
                        variation='positions'
                    />
                )}
            </Panel>
        </>
    );
};


BalancesPanel.propTypes = {
    loadingAssets: PropTypes.bool,
    portfolioData: PropTypes.array,
    tickers: PropTypes.object
};


export default BalancesPanel;
