import React from 'react';

// Ant Design Imports
import { Card as AntdCard, Row as AntdRow, Col as AntdCol, Avatar, Table, Typography as AntdTypography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

// Custom Components
import { Title } from 'components/imports';

// Ant Design Typography
const { Text } = AntdTypography;

const OrderDetails = () => {

    // Mock data
    const data = [
        {
            key: '1',
            exchange: 'Binance',
            time: '07-18 00:10:24',
            pair: 'BTC/USDT',
            type: 'Limit',
            side: 'Buy',
            averagePrice: '64,230.66',
            executed: '0.001',
            amount: '0.001',
            total: '64.73',
            tp_sl: '—',
            status: 'Filled',
        },
    ];

    // Table columns configuration
    const columns = [
        {
            title: '',
            dataIndex: 'exchange',
            key: 'exchange',
            render: () => (
                <Avatar.Group>
                    <Avatar src="binance-icon-url" />
                </Avatar.Group>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Pair',
            dataIndex: 'pair',
            key: 'pair',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
            render: (filled) => (
                <span style={{ color: filled === 'Buy' ? '#52C41A' : '#FF4D4F' }}>{filled}</span>
            ),
        },
        {
            title: 'Average Price',
            dataIndex: 'averagePrice',
            key: 'averagePrice',
        },
        {
            title: 'Executed',
            dataIndex: 'executed',
            key: 'executed',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'TP/SL',
            dataIndex: 'tp_sl',
            key: 'tp_sl',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (side) => (
                <span style={{ color: side === 'Filled' ? '#52C41A' : '#FF4D4F' }}>{side}</span>
            ),
        },
    ];

    return (
        <AntdCard style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <AntdRow>
                <AntdCol span={24}>
                    <Title variation="tertiary" txt="Order Details" />
                </AntdCol>
            </AntdRow>

            <AntdRow style={{ marginTop: '16px' }}>
                <AntdCol span={24}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                        <CheckCircleOutlined style={{ color: 'green', fontSize: '24px', marginRight: '8px' }} />
                        <div>
                            <Text strong style={{ fontSize: '16px' }}>Success</Text>
                            <br />
                            <Text type="secondary">Order created and filled</Text>
                        </div>
                    </div>
                </AntdCol>
            </AntdRow>

            <AntdRow>
                <AntdCol span={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        style={{ marginTop: '16px' }}
                    />
                </AntdCol>
            </AntdRow>
        </AntdCard>
    );
};

export default OrderDetails;
