/* eslint-disable @typescript-eslint/no-unused-vars */
// Common Imports (used in multiple pages)
import React, { useEffect, useRef, useState } from 'react'; // React
import { useLocation, useNavigate } from 'react-router-dom'; // React Router

import i18n from 'locales/i18n';
import $ from 'jquery'; // JQuery

// React-i18next
import { useTranslation } from 'react-i18next';

// Reactour
import { useTour } from '@reactour/tour';

// Element Components
import {
    Column, CookiesPolicyModal, MessagePopup, Row
} from 'components/imports'; // Common
import { Layout, Loading, Page } from 'components/imports'; // Layout

// Shared Page Components
import { BalancesPanel } from 'components/imports';

// Page Components
import ArticlesPanel from './components/ArticlesPanel';
import SignalsPanel from './components/SignalsPanel';
import TopListPanel from './components/TopListPanel';

// Icons
import { getIcon, loadIcons } from 'icons/imports'; // Helpers

// Images
import { DefaultNewsBannerPng, getImage } from 'images/imports';

// API Endpoints
import { balancesMeGet, newsGet } from 'apis/imports';

// Utils
import { getCryptocurrencyName } from 'utils/cryptocurrencies';
import {
    getAgreedCookiesPolicy, getCurrency, getLanguage,
    getTheme, setAgreedCookiesPolicy
} from 'utils/cookies';
import { getGlobalExchanges } from 'utils/exchanges';
import PriceWatcher from 'utils/priceWatcher';

// PDF
import Pdf from '../../documents/cookies-policy/cookies-policy.pdf';

// Styles
import 'react-loading-skeleton/dist/skeleton.css';
import './Overview.css';
import './OverviewBrowser.css';
import './OverviewMobile.css';


const Overview = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'overview';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());
    const [appCurrency,] = useState(getCurrency());

    const [popUpDuration,] = useState(3000);
    const [popUpLevel,] = useState('warning');
    const [popUpText,] = useState('-');

    const [assetIcons, setAssetIcons] = useState({});
    const [exchanges,] = useState(getGlobalExchanges({}));
    const [news, setNews] = useState([]);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState('');
    const [tickers, setTickers] = useState({});
    const [tickerTapeCoins, setTickerTapeCoins] = useState([]);
    const [topGainerCoins, setTopGainerCoins] = useState([]);
    const [topLooserCoins, setTopLooserCoins] = useState([]);
    const [topVolumeCoins, setTopVolumeCoins] = useState([]);

    const [loadingAssets, setLoadingAssets] = useState(true);
    const [loadingCryptos, setLoadingCryptos] = useState(true);
    const [loadingNews, setLoadingNews] = useState(true);

    const priceWatcherRef = useRef(null);

    const priceWatcher = new PriceWatcher();
    priceWatcherRef.current = priceWatcher;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('overview')} - Smart Trade`;

            const priceWatcher = new PriceWatcher();
            priceWatcherRef.current = priceWatcher;

            // Start watching prices
            priceWatcher.start({
                exchanges: ['binance', 'kucoin'], // Only watch Binance
                interval: 2000, // The interval at which onUpdate is called
                targetCurrency: appCurrency,
                onUpdate: (data) => {
                    // This function is called every 3 seconds with the latest data
                    let iconNames = Object.keys(data.assets).map(asset => asset.toLowerCase());
                    loadIcons(iconNames).then(imagesDict => {
                        setAssetIcons(imagesDict);
                        setTickers(data);
                        setLoadingCryptos(false);
                    });
                },
            });

            getPortfolioData();
            getNews();
        }
        else priceWatcher.stop();

    }, [location.pathname]);


    $('.reactour__close-button').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    $('.reactour__mask').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        const exchange = selectedExchange.toLowerCase();

        if (!(exchange in tickers)) return;

        var symbolsTickers = tickers[exchange];

        let tickerList = Object.values(symbolsTickers);
        if (!tickerList.length) return;

        getTickerTopList(tickerTapeCoins, setTickerTapeCoins, tickerList, assetIcons, 'quoteVolume', 'desc', 10);
        getTickerTopList(topGainerCoins, setTopGainerCoins, tickerList, assetIcons, 'pnl', 'desc', 3);
        getTickerTopList(topLooserCoins, setTopLooserCoins, tickerList, assetIcons, 'pnl', 'asc', 3);
        getTickerTopList(topVolumeCoins, setTopVolumeCoins, tickerList, assetIcons, 'quoteVolume', 'desc', 3);
    }, [tickers]);


    useEffect(() => {
        if (exchanges.length > 0) setSelectedExchange(exchanges[0]);
    }, [exchanges]);


    const getTickerTopList = (getter, setter, list, assetIcons, sortBy, sortDirection, limit) => {
        let getterAux = [...getter];
        let listAux = [...list];

        // Lógica para manter valores que por algum motivo não são retornados
        // em alguns casos durante o loop de atualização
        getterAux.forEach((item) => {
            let { symbol, pnl, price, quoteVolume } = item;
            let found = listAux.find((item) => item.base === symbol);
            if (!found) {
                listAux.push({
                    base: symbol,
                    last: price,
                    pnl,
                    quoteVolume
                });
            }
        });

        if (sortDirection === 'asc') listAux.sort((a, b) => a[sortBy] - b[sortBy]);
        else listAux.sort((a, b) => b[sortBy] - a[sortBy]);

        listAux = listAux.slice(0, limit);
        listAux = listAux.map((ticker) => {
            const { base, last, pnl, quoteVolume } = ticker;
            return {
                icon: assetIcons[base.toLowerCase()],
                name: getCryptocurrencyName(base),
                pnl,
                price: last,
                symbol: base,
                quoteVolume
            };
        });

        setter(listAux);
    };


    const getPortfolioData = async () => {

        setLoadingAssets(true);

        const result = await balancesMeGet();

        setLoadingAssets(false);

        if (!result.isSuccess) return;

        var values = result.response;
        var iconNames = values.map(asset => asset.symbol.toLowerCase());
        var missedIcons = iconNames.filter(iconName => !assetIcons[iconName]);

        loadIcons(missedIcons).then(imagesDict => {
            let updatedAssetIcons = { ...assetIcons, ...imagesDict };
            setAssetIcons(updatedAssetIcons);

            const data = values.map((value) => {
                const iconName = value.symbol.toLowerCase();
                return ({
                    ...value,
                    name: getCryptocurrencyName(value.symbol),
                    icon: updatedAssetIcons[iconName]
                });
            });

            setPortfolioData(data);
        });
    };


    const getNews = async () => {

        const result = await newsGet('day');

        if (result.isSuccess) {

            let items = result.response.reduce((acc, item) => {

                if (!item.banner) item.banner = DefaultNewsBannerPng;

                item.timestamp = item.publishedAt * 1000;

                item.sourceIcon = getIcon(item.source);
                item.sourceImage = getImage(item.source);

                item.authors = item.authors.join(' • ');
                item.authorsMin = item.authors.length >= 30 ? item.authors.substring(0, 27) + '...' : item.authors;
                item.authors = 'By ' + item.authors;

                item.id = `article_${item.publishedAt}_${item.api}_${item.source}`;

                acc.push(item);
                return acc;
            }, []);

            // Keep only articles from the last day
            items = items.reduce((acc, item) => {
                if (new Date() - new Date(item.publishedAtISO8601) < 86400000) acc.push(item);
                return acc;
            }, []);

            setNews(items);
            setLoadingNews(false);
        }
    };


    const handleIAgreeButton = (event) => {
        var option = event.target.id.replace('-btn', '');
        if (option === 'yes') setAgreedCookiesPolicy();
    };


    const handleLearnMoreButton = (event) => {
        var option = event.target.id.replace('-btn', '');
        if (option === 'no') window.open(Pdf);
    };


    return (
        <Page id={pageId} >
            <Loading id={'overview'} />

            <CookiesPolicyModal
                handle={handleIAgreeButton}
                handleClickOut={handleLearnMoreButton}
                showDialog={!getAgreedCookiesPolicy()}
                text={t('cookies.messages.content')}
            />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                showToolbar={true}
                showHeader={true}
                tickerTapeCoins={tickerTapeCoins}
            >
                <Row
                    a='unset'
                    className='main-row'
                    fill='width'
                >
                    {/* First Column */}
                    <Column w='8'>
                        <BalancesPanel
                            loadingAssets={loadingAssets}
                            portfolioData={portfolioData}
                            tickers={tickers}
                        />
                    </Column>

                    {/* Second Column */}
                    <Column fill='width'>
                        <Row id='top-panels'
                            fill='width'
                        >
                            <TopListPanel
                                id='top-gainers'
                                list={topGainerCoins}
                                loadingCryptos={loadingCryptos}
                                title='top-gainers'
                            />

                            <TopListPanel
                                id='top-loosers'
                                list={topLooserCoins}
                                loadingCryptos={loadingCryptos}
                                title='top-loosers'
                            />

                            <TopListPanel
                                id='top-volumes'
                                list={topVolumeCoins}
                                loadingCryptos={loadingCryptos}
                                title='top-volumes'
                            />
                        </Row>

                        <SignalsPanel
                            appLang={appLang}
                            pageId={pageId}
                            selectedExchange={selectedExchange}
                            tickers={tickers}
                        />
                    </Column>

                    <Column w='8'>
                        <ArticlesPanel
                            appLang={appLang}
                            loadingNews={loadingNews}
                            news={news}
                        />
                    </Column>
                </Row>
            </Layout>
        </Page >
    );
};

export default Overview;
