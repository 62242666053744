/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { Tag } from 'antd';

import { Column, Row, Title } from 'components/imports';


const ExecutionChart = ({ data, signals, fullExecutionsData, theme }) => {
    const chartContainerRef = useRef(null);
    const chartInstanceRef = useRef(null);

    const [crosshairData, setCrosshairData] = useState(getInitialCrosshairData());

    // const candlestickData = [
    //     { time: 1725858000, open: 105, high: 115, low: 100, close: 110 },
    //     { time: 1725861600, open: 106, high: 116, low: 101, close: 111 },
    //     { time: 1725865200, open: 107, high: 117, low: 102, close: 112 },
    //     { time: 1725868800, open: 108, high: 118, low: 103, close: 113 },
    //     { time: 1725872400, open: 109, high: 119, low: 104, close: 114 }
    // ];

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.remove();
        }

        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 250,
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            ...getThemeParams(theme),
        });

        if (data && Array.isArray(data.adx)) {

            const maxValue = Math.max(...data.adx.map(item => item.value));

            signals.forEach((signal, index) => {
                const nextSignal = signals[index + 1];
                const timeStart = signal.time;
                const timeEnd = nextSignal ? nextSignal.time : data.adx[data.adx.length - 1].time;

                if (timeEnd <= timeStart) {
                    console.warn('Invalid time range: timeEnd should be greater than timeStart');
                    return;
                }

                let backgroundColor = 'transparent';
                if (signal.trendLabel === 'increasing') {
                    backgroundColor = 'rgba(123, 194, 244, 0.2)';
                } else if (signal.trendLabel === 'decreasing') {
                    backgroundColor = 'rgba(255, 136, 0, 0.2)';
                } else if (signal.trendLabel === 'missing') {
                    backgroundColor = 'rgba(255, 0, 0, 0.2)';
                }

                if (typeof maxValue === 'undefined' || maxValue === null) {
                    console.warn('maxValue is not defined.');
                    return;
                }

                chart.addAreaSeries({
                    topColor: backgroundColor,
                    bottomColor: backgroundColor,
                    lineColor: 'transparent',
                    lineWidth: 0,
                    priceLineVisible: false,
                    crossHairMarkerVisible: false,
                    priceLabelsVisible: false,
                    lastValueVisible: false,
                    autoscaleInfoProvider: () => null,
                }).setData([
                    { time: timeStart, value: maxValue },
                    { time: timeEnd, value: maxValue },
                ]);
            });

            const adxSeries = chart.addLineSeries({ color: '#1D96EE', lineWidth: 1 });
            adxSeries.setData(data.adx);

            // ---- Candles ----
            // const candlestickSeries = chart.addCandlestickSeries({
            //     upColor: '#4caf50',
            //     downColor: '#f44336',
            //     borderVisible: true,
            //     wickUpColor: '#4caf50',
            //     wickDownColor: '#f44336',
            // });

            // candlestickSeries.setData(candlestickData);
            // ---- Candles ----

            if (Array.isArray(signals)) {
                const markers = generateMarkers(signals);
                adxSeries.setMarkers(markers);
            }

            chart.subscribeCrosshairMove((param) => {
                if (!param || !param.time) return;

                const adxValue = data.adx.find(item => item.time === param.time)?.value || 0;
                const diPlusValue = data.diPlus.find(item => item.time === param.time)?.value || 0;
                const diMinusValue = data.diMinus.find(item => item.time === param.time)?.value || 0;
                const diPlusMinusDiMinusValue = diPlusValue - diMinusValue;
                const diThreshold = fullExecutionsData.executions.find(val => val.timestamp_ms === param.time * 1000)?.debug?.buy?.di_threshold || 0;
                const divergenceThreshold = fullExecutionsData.executions.find(val => val.timestamp_ms === param.time * 1000)?.debug?.buy?.divergence_threshold || 0;
                const decreasingPeriod = fullExecutionsData.executions.find(val => val.timestamp_ms === param.time * 1000)?.debug?.buy?.decreasing_period || 0;

                const findItem = fullExecutionsData.executions.find(item => item.timestamp_ms === param.time * 1000);
                const details = {
                    exchange_name: findItem?.exchange_name || '-',
                    hyperparams_version: findItem?.hyperparams_version || '-',
                    strategy_codename: findItem?.strategy_codename || '-',
                    timeframe: findItem?.timeframe || '-',
                    pair: findItem?.pair || '-',
                    window_size: findItem?.window_size || '-',
                };

                const formattedDate = new Date(param.time * 1000).toLocaleString();

                setCrosshairData({
                    time: formattedDate,
                    adx: adxValue,
                    diPlus: diPlusValue,
                    diMinus: diMinusValue,
                    diPlusMinusDiMinus: diPlusMinusDiMinusValue,
                    diThreshold: diThreshold,
                    divergenceThreshold: divergenceThreshold,
                    decreasingPeriod: decreasingPeriod,
                    details: details,
                });
            });
        }

        if (data && Array.isArray(data.diPlus)) {
            const diPlusSeries = chart.addLineSeries({ color: '#2C8C64', lineWidth: 1 });
            diPlusSeries.setData(data.diPlus);
        }

        if (data && Array.isArray(data.diMinus)) {
            const diMinusSeries = chart.addLineSeries({ color: '#EB5353', lineWidth: 1 });
            diMinusSeries.setData(data.diMinus);
        }

        chartInstanceRef.current = chart;
    }, [data, signals, theme]);

    const renderHyperparameters = () => {
        const hyperparamsVersion = crosshairData?.details?.hyperparams_version;
        const hyperparams = fullExecutionsData?.hyperparams?.[hyperparamsVersion];

        let dict = {
            adx_period: 'ADX Period',
            di_threshold: 'DI Threshold',
            decreasing_period: 'Decreasing Period',
            id_period: 'ID Period',
            monotonic_window: 'Monotonic Window',
            divergence_threshold: 'Divergence Threshold',
        };

        if (!hyperparams) return null;

        const renderCard = (title, params) => (
            <Column
                className='hyperparams-card'
                fit='all'
                p='pri'
            >
                <Title variation='tertiary' txt={title} />

                <Column
                    fit='all'
                    g='0'
                >
                    {Object.keys(params).map((key) => {
                        const value = params[key];
                        const formattedValue = typeof value === 'number' ? value.toFixed(10) : value;

                        return (<label key={key}><b>{dict[key]}:</b> {formattedValue}</label>);
                    })}
                </Column>
            </Column>
        );


        return (
            <Column
                fit='all'
            >
                <Title variation='tertiary' txt={'Hyperparameters'} />

                <Row fill='all'>
                    <Column w='8'>
                        {renderCard('Increasing', hyperparams.increasing || { defaultParam: '-' })}
                    </Column>
                    <Column w='8'>
                        {renderCard('Decreasing', hyperparams.decreasing || { defaultParam: '-' })}
                    </Column>
                    <Column w='8'>
                        {renderCard('Stable', hyperparams.stable || { defaultParam: '-' })}
                    </Column>
                </Row>
            </Column>
        );
    };

    const generateMarkers = (signals) => {

        signals = signals.filter(item => item.side !== '-');

        return signals.flatMap((signal) => {
            const baseMarker = {
                time: signal.time,
                position: 'aboveBar',
                color: signal.side === 'buy' ? '#2C8C64' : '#EB5353',
                shape: 'arrowDown',
                text: signal.side.charAt(0).toUpperCase() + signal.side.slice(1),
                size: 1,
                rightOffset: 10,
            };

            const conditionMarkers = [];

            if (signal.divergenceConditionMet) {
                conditionMarkers.push({
                    ...baseMarker,
                    position: 'aboveBar',
                    color: '#3C398D',
                    shape: 'circle',
                    text: '',
                    size: 1,
                });
            }

            if (signal.slopeConditionMet) {
                conditionMarkers.push({
                    ...baseMarker,
                    position: 'aboveBar',
                    color: '#9747FF',
                    shape: 'circle',
                    text: '',
                    size: 1,
                });
            }

            if (signal.adxMonotonic) {
                conditionMarkers.push({
                    ...baseMarker,
                    position: 'aboveBar',
                    color: '#EB7C53',
                    shape: 'circle',
                    text: '',
                    size: 1,
                });
            }

            return [baseMarker, ...conditionMarkers];
        });
    };

    return (
        <Column
            fill='all'
            m='pri-ver'
        >
            <Row
                fill='width'
                j="end"
            >
                <LegendTag color="#7BC2F450" text="Bullish Trend" />
                <LegendTag color="#FF880050" text="Bearish Trend" />
                <LegendTag color="#FF000050" text="Server Error" />
                <LegendTag color="#3C398D" shape="circle" text="Divergence Condition Met" />
                <LegendTag color="#9747FF" shape="circle" text="Slope Condition Met" />
                <LegendTag color="#EB7C53" shape="circle" text="ADX is Monotonic" />
            </Row>

            <Row
                w='24'
            >
                <div
                    ref={chartContainerRef} style={{ width: '100%', height: '250px' }}
                />
            </Row>

            {crosshairData && (
                <Column
                    fill='all'
                >
                    <Title variation='secondary' txt={crosshairData.time} />

                    <Row
                        fill='width'
                    >
                        <Row w='6'>
                            <label><b>ADX:</b></label>
                            <label style={{ color: '#1D96EE' }}>{crosshairData.adx}</label>
                        </Row>

                        <Row w='6'>
                            <label><b>DI+</b></label>
                            <label style={{ color: '#2C8C64' }}>{crosshairData.diPlus}</label>
                        </Row>

                        <Row w='6'>
                            <label><b>DI-</b></label>
                            <label style={{ color: '#EB5353' }}>{crosshairData.diMinus}</label>
                        </Row>

                        <Row w='6'>
                            <label><b>DI+ minus DI-</b></label>
                            <label style={{ color: '#A64EF9' }}>{crosshairData.diPlusMinusDiMinus}</label>
                        </Row>
                    </Row>
                    <Row
                        fill='width'
                    >
                        <Row w='6'>
                            <label><b>DI Threshold:</b></label>
                            <label>{crosshairData.diThreshold}</label>
                        </Row>

                        <Row w='6'>
                            <label><b>Divergence Threshold:</b></label>
                            <label>{crosshairData.divergenceThreshold}</label>
                        </Row>

                        <Row w='6'>
                            <label><b>Decreasing Period</b></label>
                            <label>{crosshairData.decreasingPeriod}</label>
                        </Row>
                    </Row>

                    <Title variation='tertiary' txt={'Details'} />

                    <Row
                        fill='width'
                    >
                        <Row w='6'>
                            <b>Strategy:</b>

                            <b style={{ color: 'var(--color-secondary)' }}>
                                {crosshairData.details.strategy_codename.toUpperCase()}
                            </b>
                        </Row>
                        
                        <Row w='6'>
                            <b>Exchange:</b>

                            <b style={{ color: 'var(--color-secondary)' }}>
                                {crosshairData.details.exchange_name}
                            </b>
                        </Row>

                        <Row w='6'>
                            <b>Timeframe:</b>

                            <b style={{ color: 'var(--color-secondary)' }}>
                                {crosshairData.details.timeframe}
                            </b>
                        </Row>

                        <Row w='6'>
                            <b>Window Size:</b>

                            <b style={{ color: 'var(--color-secondary)' }}>
                                {crosshairData.details.window_size}
                            </b>
                        </Row>
                    </Row>

                    {renderHyperparameters()}
                </Column>
            )}
        </Column>
    );
};

export default ExecutionChart;

function LegendTag({ color, text, shape = 'default' }) {
    return (
        <Row
            a='center'
        >
            <Tag
                color={color}
                bordered={false}
                style={{
                    width: '10px',
                    height: '15px',
                    borderRadius: shape === 'circle' ? '50%' : '0%',
                    marginRight: '5px',
                    marginLeft: '15px',
                }}
            />

            <p>{text}</p>
        </Row>
    );
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });
};

function getThemeParams(theme) {
    switch (theme) {
        case 'dark':
            return {
                layout: {
                    background: {
                        color: '#1D1C1F'
                    },
                    textColor: '#A6A6A6',
                },
                grid: {
                    vertLines: { color: '#161635' },
                    horzLines: { color: '#161635' },
                },
                timeScale: {
                    borderColor: '#cccccc',
                    tickMarkFormatter: (time) => formatDate(time),
                },
            };
        default:
            return {
                layout: {
                    backgroundColor: '#ffffff',
                    textColor: 'rgba(33, 56, 77, 1)',
                },
                grid: {
                    vertLines: { color: '#F0F3FA' },
                    horzLines: { color: '#F0F3FA' },
                },
                timeScale: {
                    borderColor: '#cccccc',
                    tickMarkFormatter: (time) => formatDate(time),
                },
            };
    }
}

function getInitialCrosshairData() {
    return {
        time: '-',
        adx: 0,
        diPlus: 0,
        diMinus: 0,
        diPlusMinusDiMinus: 0,
        diThreshold: 0,
        divergenceThreshold: 0,
        decreasingPeriod: 0,
        details: {
            exchange_name: '-',
            hyperparams_version: '-',
            strategy_codename: '-',
            timeframe: '-',
            pair: '-',
            window_size: '-',
        },
    };
}
