import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';

import { Panel, Row, List, Title } from 'components/imports';
// Tradução
import { useTranslation } from 'react-i18next';


const TopListPanel = (props) => {
    const { t } = useTranslation();
    
    return (
        <Panel id={props.id}
            fill='width'
        >
            <Title variation='tertiary' txt={t(props.title)} />

            <hr />
            {props.loadingCryptos ? (
                <Row id={props.id + '-skeleton'}
                    fill='width'
                    j='between'
                >
                    <Row>
                        <Skeleton
                            className='skeleton'
                            containerClassName='avatar-skeleton'
                            circle
                            count={3}
                            height='35px'
                            style={{ marginBottom: '.5rem' }}
                            width='35px'
                        />

                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'35px'}
                            style={{ marginBottom: '.5rem' }}
                            width={'5rem'}
                        />
                    </Row>

                    <Skeleton
                        className='skeleton'
                        count={3}
                        height={'35px'}
                        style={{ marginBottom: '.5rem' }}
                        width={'3rem'}
                    />
                </Row>
            ) : (
                <List
                    id={props.id + '-list'}
                    items={props.list}
                    variation='coin-variation'
                />
            )}
        </Panel>);
};


TopListPanel.propTypes = {
    loadingCryptos: PropTypes.bool,
    list: PropTypes.array,
    id: PropTypes.string,
    title: PropTypes.string
};


export default TopListPanel;
