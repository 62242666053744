import PropTypes from 'prop-types';
import $ from 'jquery';

import { appEnv, userService as users } from 'constants';

import { getAuthorization } from 'utils/cookies';


const exchangesConnectionsMePost = async (props) => {

    const { exchangeName, apiKey, secret, password } = props;

    const method = 'post';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/exchanges/connections/me`;

    const auth = await getAuthorization();

    var formdata = new FormData();
    formdata.append('exchange_name', exchangeName);
    formdata.append('api_key', apiKey);
    formdata.append('secret', secret);
    if (password) formdata.append('password', password);

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: formdata,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = JSON.parse(response);

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {

            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};


exchangesConnectionsMePost.propTypes = {
    exchangeName: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    secret: PropTypes.string.isRequired,
    password: PropTypes.string
};


export { exchangesConnectionsMePost };
