/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// Ant Design Imports
import { Table, Row as AntdRow, Col as AntdCol, Card as AntdCard, Select, Avatar } from 'antd';

// Custom Components
import { Title } from 'components/imports';

// Icon Imports
import { getIcon } from 'icons/imports';

// Ant Design Select component
const { Option } = Select;

const OrdersHistory = () => {

    // Mock data
    const data = [
        {
            key: '1',
            exchange: 'Binance',
            time: '07-18 00:10:24',
            pair: 'BTC/USDT',
            type: 'Limit',
            side: 'Buy',
            status: 'Filled',
        },
        {
            key: '2',
            exchange: 'Binance',
            time: '07-18 00:00:13',
            pair: 'BTC/USDT',
            type: 'Market',
            side: 'Sell',
            status: 'Canceled',
        },
        {
            key: '3',
            exchange: 'Binance',
            time: '07-17 23:40:44',
            pair: 'BTC/USDT',
            type: 'Limit',
            side: 'Buy',
            status: 'Filled',
        },
    ];

    const [side, setSide] = useState('Buy, Sell');
    const [status, setStatus] = useState('Filled, Canceled');
    const [exchange, setExchange] = useState('Binance');
    const [baseSymbol, setBaseSymbol] = useState('Bitcoin');
    const [quoteSymbol, setQuoteSymbol] = useState('All symbols');

    const handleFilterChange = (value, type) => {
        if (type === 'side') setSide(value);
        if (type === 'status') setStatus(value);
        if (type === 'exchange') setExchange(value);
        if (type === 'baseSymbol') setBaseSymbol(value);
        if (type === 'quoteSymbol') setQuoteSymbol(value);
    };

    // Table columns configuration
    const columns = [
        {
            title: '',
            dataIndex: 'exchange',
            key: 'exchange',
            render: (exchange) => (
                <Avatar.Group>
                    <Avatar src={getIcon(exchange)} />
                </Avatar.Group>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Pair',
            dataIndex: 'pair',
            key: 'pair',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
            render: (side) => (
                <span style={{ color: side === 'Buy' ? '#52C41A' : '#FF4D4F' }}>{side}</span>
            ),
        },
    ];

    return (
        <AntdCard style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <AntdRow>
                <AntdCol span={12}>
                    <Title variation="tertiary" txt="Orders History" />
                </AntdCol>
            </AntdRow>

            <AntdRow gutter={[16, 16]}>
                <AntdCol span={12}>
                    <Select
                        defaultValue={side}
                        onChange={(value) => handleFilterChange(value, 'side')}
                        style={{ width: '100%' }}
                    >
                        <Option value="Buy, Sell">Buy, Sell</Option>
                        <Option value="Buy">Buy</Option>
                        <Option value="Sell">Sell</Option>
                    </Select>
                </AntdCol>

                <AntdCol span={12}>
                    <Select
                        defaultValue={status}
                        onChange={(value) => handleFilterChange(value, 'status')}
                        style={{ width: '100%' }}
                    >
                        <Option value="Filled, Canceled">Filled, Canceled</Option>
                        <Option value="Filled">Filled</Option>
                        <Option value="Canceled">Canceled</Option>
                    </Select>
                </AntdCol>

                <AntdCol span={12}>
                    <Select
                        defaultValue={exchange}
                        onChange={(value) => handleFilterChange(value, 'exchange')}
                        style={{ width: '100%' }}
                    >
                        <Option value="Binance">
                            <Avatar src={getIcon('Binance')} /> Binance
                        </Option>

                        <Option value="KuCoin">
                            <Avatar src={getIcon('KuCoin')} /> KuCoin
                        </Option>
                    </Select>
                </AntdCol>

                <AntdCol span={12}>
                    <Select
                        defaultValue={baseSymbol}
                        onChange={(value) => handleFilterChange(value, 'baseSymbol')}
                        style={{ width: '100%' }}
                    >
                        <Option value="Bitcoin">
                            <Avatar src="btc-icon-url" /> Bitcoin
                        </Option>

                        <Option value="Ethereum">
                            <Avatar src="eth-icon-url" /> Ethereum
                        </Option>
                    </Select>
                </AntdCol>

                <AntdCol span={24}>
                    <Select
                        defaultValue={quoteSymbol}
                        onChange={(value) => handleFilterChange(value, 'quoteSymbol')}
                        style={{ width: '100%' }}
                    >
                        <Option value="All symbols">All symbols</Option>
                        <Option value="USDT">USDT</Option>
                        <Option value="USD">USD</Option>
                    </Select>
                </AntdCol>
            </AntdRow>

            <AntdRow>
                <AntdCol>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        style={{ marginTop: '16px' }}
                    />
                </AntdCol>
            </AntdRow>
        </AntdCard>
    );
};

export default OrdersHistory;
