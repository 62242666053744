// utils/priceWatcher.js

import { exchangeConstructor, getGlobalExchanges } from './exchanges';

/**
 * Class to watch tickers from exchanges using CCXT's watchTickers method,
 * focusing on 'last' price, returning PnL, converting to a target currency,
 * and returning only tickers for pairs ending with '/USDT' or '/BTC'.
 * Exchange rates are obtained from the tickers data.
 */
class PriceWatcher {
    constructor() {
        this.assets = {};
        this.exchanges = {};
        this.tickers = {};
        this.exchangeRates = {}; // Store exchange rates for conversion
        this.callbacks = [];
        this.interval = null;
        this.targetCurrency = 'USDT'; // Default target currency
    }

    /**
     * Starts watching tickers.
     * @param {Object} options
     * @param {string[]} [options.exchanges] - List of exchanges to watch.
     * @param {number} [options.interval] - Interval in milliseconds to call the callback function.
     * @param {Function} [options.onUpdate] - Callback function to receive the data.
     * @param {string} [options.targetCurrency] - Currency to convert prices to (e.g., 'USDT', 'BTC', 'BRL', 'EUR').
     */
    async start({ exchanges, interval = 3000, onUpdate, targetCurrency = 'USDT' }) {
        if (!exchanges) {
            exchanges = getGlobalExchanges({});
        }

        this.targetCurrency = targetCurrency;

        // Set up the callback function
        if (onUpdate) {
            this.callbacks.push(onUpdate);
        }

        // Set up watchTickers for each exchange
        await Promise.all(
            exchanges.map(async (exchangeName) => {
                const exchange = await exchangeConstructor(exchangeName, 'pro');
                if (exchange && exchange.has['watchTickers']) {

                    try {
                        await exchange.loadMarkets();
                        this.exchanges[exchangeName] = exchange;

                        // Initialize tickers storage for the exchange
                        this.tickers[exchangeName] = {};

                        this.watchTickers(exchangeName);
                    }
                    catch (error) {
                        console.error(error);
                    }
                } else {
                    console.warn(`Exchange ${exchangeName} does not support watchTickers.`);
                }
            })
        );

        // Set up interval to call the callbacks every specified interval
        this.interval = setInterval(() => {
            const data = this.getTickers();
            this.callbacks.forEach((cb) => cb(data));
        }, interval);
    }

    /**
     * Stops watching tickers.
     */
    stop() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        // Close exchange connections
        Object.values(this.exchanges).forEach((exchange) => {
            if (exchange) {
                exchange.close();
            }
        });
    }

    /**
     * Internal method to watch tickers for an exchange.
     * @param {string} exchangeName
     */
    async watchTickers(exchangeName) {
        const exchange = this.exchanges[exchangeName];
        if (!exchange) return;

        try {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const tickers = await exchange.watchTickers();

                // Update exchange rate from tickers
                this.updateExchangeRate(exchangeName, tickers);

                // Format the tickers data
                const formattedTickers = this.formatTickers(exchangeName, tickers);

                this.assets = { ...this.assets, ...this.getAssets(formattedTickers) };

                // Update the tickers data
                this.tickers[exchangeName] = formattedTickers;
            }
        } catch (error) {
            console.error(`Error watching tickers on ${exchangeName}:`, error);
            // Optionally, implement reconnection logic here
        }
    }

    /**
     * Updates the exchange rate needed for converting prices to the target currency.
     * Extracts the rate from the tickers data.
     * @param {string} exchangeName - The name of the exchange.
     * @param {Object} tickers - The tickers data.
     */
    updateExchangeRate(exchangeName, tickers) {
        let rate = null;
        const targetCurrency = this.targetCurrency;

        if (targetCurrency === 'USDT') {
            rate = 1; // No conversion needed
        } else {
            if (targetCurrency === 'BTC') {
                // Need to find 'BTC/USDT' or 'USDT/BTC'
                if (tickers['BTC/USDT']) {
                    rate = 1 / tickers['BTC/USDT'].last; // Convert from USDT to BTC
                } else if (tickers['USDT/BTC']) {
                    rate = tickers['USDT/BTC'].last; // Convert from USDT to BTC
                }
            } else if (targetCurrency === 'BRL' || targetCurrency === 'EUR') {
                if (tickers[`USDT/${targetCurrency}`]) {
                    rate = tickers[`USDT/${targetCurrency}`].last; // USDT to BRL/EUR
                } else if (tickers[`${targetCurrency}/USDT`]) {
                    rate = 1 / tickers[`${targetCurrency}/USDT`].last; // USDT to BRL/EUR
                }
            }
        }

        if (rate !== null && rate !== undefined && !isNaN(rate)) {
            this.exchangeRates[exchangeName] = rate;
        } else {
            // Keep previous rate or set to 1 if no previous rate
            this.exchangeRates[exchangeName] = this.exchangeRates[exchangeName] || 1;
        }
    }

    /**
     * Formats tickers data, converts prices to the target currency,
     * returns only 'last' price and PnL, and filters pairs ending with '/USDT'
     * @param {string} exchangeName - The name of the exchange.
     * @param {Object} tickers - The raw tickers data.
     * @returns {Object} - The formatted tickers data.
     */
    formatTickers(exchangeName, tickers) {
        const formattedTickers = {};
        const exchange = this.exchanges[exchangeName];
        const exchangeRate = this.exchangeRates[exchangeName];
        const precision = this.getCurrencyPrecision(this.targetCurrency);

        for (const symbol in tickers) {

            // Filter tickers to include only pairs ending with '/USDT'
            if (!symbol.endsWith('/USDT')) {
                continue;
            }

            const [base, quote] = symbol.split('/');
            const pair = symbol;

            const ticker = tickers[symbol];
            const market = exchange.markets[symbol];

            if (market) {
                let { last, quoteVolume } = ticker;

                // Convert the last price to targetCurrency using exchangeRate
                if (exchangeRate !== null && exchangeRate !== undefined) {
                    last = last * exchangeRate;
                }

                // Format the last price according to target currency precision
                last = this.formatNumber(last, precision);

                // Get PnL (percentage change)
                const pnl = ticker.percentage;

                formattedTickers[symbol] = {
                    pair,
                    base,
                    quote,
                    last,
                    quoteVolume,
                    pnl,
                    precision,
                };
            }
        }

        return formattedTickers;
    }

    /**
     * Formats a number according to the given precision.
     * @param {number} value - The number to format.
     * @param {number} precision - The number of decimal places.
     * @returns {number} - The formatted number.
     */
    formatNumber(value, precision) {
        if (value === undefined || value === null) return value;
        return parseFloat(value.toFixed(precision));
    }

    /**
     * Gets the list of assets from the tickers data.
     * @param {Object} tickers - The tickers data.
     * @returns {Array} - The list of bases.
     */
    getAssets(tickers) {
        var assets = {};
        for (const symbol in tickers) {
            const [base] = symbol.split('/');
            assets[base] = true;
        }
        return assets;
    }

    /**
     * Returns the current tickers data.
     */
    getTickers() {
        return { ...{ assets: this.assets }, ...this.tickers };
    }

    /**
     * Gets the precision for the target currency.
     * @param {string} targetCurrency - The target currency.
     * @returns {number} - The precision (number of decimal places).
     */
    getCurrencyPrecision(targetCurrency) {
        const precisions = {
            USDT: 8,
            BTC: 8,
            BRL: 2,
            EUR: 2,
        };
        return precisions[targetCurrency] || 8; // Default to 8 if not specified
    }
}

export default PriceWatcher;
