import React from 'react';
import PropTypes from 'prop-types';

// Ant Design Imports
import { Skeleton } from 'antd';

// Custom Components and Utilities
import { Button, Column, DonutChart, Row, Panel, NoData, Title } from 'components/imports';
import { getImage } from 'images/imports';


const AssetsDistributionPanel = ({ loading, portfolioChart, selectedData, setSelectedData, t }) => {

    return (
        <Panel
            fill='height'
            fit='width'
        >
            <Title variation="tertiary" txt={t('Assets Distribution')} />

            {loading ? (
                <Row
                    fill='width'
                    j='center'
                >
                    <Skeleton.Input active style={{ width: '8rem', height: '24px' }} />

                    <Skeleton.Input active style={{ width: '8rem', height: '24px' }} />
                </Row>
            ) : (
                <Row
                    fill='width'
                    j='center'
                >
                    <Button id='selected-exchange'
                        className={selectedData === 'selectedExchange' ? 'selected' : ''}
                        onClick={() => setSelectedData('selectedExchange')}
                        variation='tertiary'
                    >
                        {t('selected-exchange')}
                    </Button>

                    <Button id='connected-exchanges'
                        className={selectedData === 'connectedExchanges' ? 'selected' : ''}
                        onClick={() => setSelectedData('connectedExchanges')}
                        variation='tertiary'
                    >
                        {t('connected-exchanges')}
                    </Button>
                </Row>
            )
            }

            <Column>
                {loading ? (
                    <Skeleton.Input active style={{ width: '400px', height: '240px' }} />
                ) : portfolioChart.length === 0 ? (
                    <NoData
                        text="Connect one or more exchanges and add assets to your portfolio to view your assets distribution"
                        image={getImage('Pie Chart Skeleton')}
                        imageSize="8rem"
                    />
                ) : (
                    <Column id="donut-chart" fill="width" p="0" m="0">
                        <DonutChart data={portfolioChart} canvasWidth={400} canvasHeight={240} />
                    </Column>
                )}
            </Column>
        </Panel >
    );
};

AssetsDistributionPanel.propTypes = {
    loading: PropTypes.bool.isRequired,
    portfolioChart: PropTypes.array.isRequired,
    selectedData: PropTypes.string.isRequired,
    setSelectedData: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default AssetsDistributionPanel;
