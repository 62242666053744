import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Ant Design Imports
import { Card as AntdCard, Row as AntdRow, Col as AntdCol, Typography as AntdTypography, Modal, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

// Custom Components
import { Title } from 'components/imports';

// Utils
import { currencyFormatter } from 'utils/formatters';

// Ant Design Typography
const { Text } = AntdTypography;

const InfoCard = ({ title, value, valueUnit, percentageChange, editable, onEdit }) => {

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    const handleOk = async () => {
        setLoading(true);
        if (onEdit) {
            await onEdit(newValue);
            setLoading(false);
        }
        setIsModalOpen(false);
    };

    return (
        <>
            <AntdCard style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', jContent: 'center' }}>
                <AntdRow justify="space-between" align="middle">
                    <AntdCol>
                        <Title variation="tertiary" txt={title} />
                    </AntdCol>
                </AntdRow>

                <AntdRow justify="space-between" align="middle" style={{ marginTop: '8px' }}>
                    <AntdCol>
                        <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                            {currencyFormatter(value)} {valueUnit}
                        </Text>

                        {percentageChange !== null && (
                            <Text
                                style={{
                                    marginLeft: '16px',
                                    color: percentageChange > 0 ? 'green' : 'red',
                                    fontWeight: 'bold',
                                }}
                            >
                                {percentageChange > 0 ? `+${percentageChange}%` : `${percentageChange}%`}
                            </Text>
                        )}
                    </AntdCol>

                    {editable && (
                        <AntdCol>
                            <EditOutlined
                                aria-label="edit balance"
                                style={{ color: '#1890ff', fontSize: '18px', cursor: 'pointer' }}
                                onClick={showModal}
                            />
                        </AntdCol>
                    )}
                </AntdRow>
            </AntdCard>

            <Modal
                title="Edit Balance"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="edit-simulation-balance"
                footer={[
                    <Button key="cancel" style={{ width: '6rem', height: '2.25rem' }} onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" style={{ width: '6rem', height: '2.25rem' }} loading={loading} onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <Input
                    addonAfter={valueUnit}
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    type="number"
                    style={{ height: '48px', marginTop: '8px' }}
                />
            </Modal>
        </>
    );
};

InfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    valueUnit: PropTypes.string,
    percentageChange: PropTypes.number,
    editable: PropTypes.bool,
    onEdit: PropTypes.func,
};

InfoCard.defaultProps = {
    valueUnit: '',
    percentageChange: null,
    editable: false,
    onEdit: null,
};

export default InfoCard;
