/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import i18n from 'locales/i18n';

import { useTranslation } from 'react-i18next';

// Components
import { Page, Panel, Loading, Layout, Column, Row, MessagePopup, Title, Input, Button } from 'components/imports';

import EmptyDisplay from './components/EmptyDisplay';
import ExecutionList from './components/ExecutionsList';
import ExecutionChart from './components/ExecutionChart';
import SignalsList from './components/SignalsList';

// APIs
import { atsReportsBacktestsGet, atsReportsExecutionsGet, atsReportsSignalsGet } from 'apis/imports';

// Utils
import { getLanguage, getTheme } from 'utils/cookies';

// Export data
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import './AtsReports.css';


const AtsReports = () => {

    // Default Vars
    const pageId = 'ats-reports';
    const { t } = useTranslation();
    
    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());

    // State Management
    const [popUpDuration, setPopUpDuration] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [environmentState, setEnvironmentState] = useState({
        currentLanguage: getLanguage(),
        currentTheme: getTheme(),
        segmentedValue: 'Default',
    });

    const [executionState, setExecutionState] = useState({
        mockExecutionsData: [],
        selectedExecution: null,
        fullExecutionsData: [],
        chartData: null,
        filteredSignals: {},
    });


    const [isUpdating, _] = useState(false);

    const [searchPair, setSearchPair] = useState('BTC/USDT');
    const [searchButtonState, setSearchButtonState] = useState('enabled');


    // Effects (useEffect Hooks)
    useEffect(() => {
        if (location.pathname === `/${pageId}`);
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    const handleSearchPair = (event) => {
        setSearchPair(event.target.value);
    };


    const handleSearch = async (event) => {

        event.preventDefault();

        const pair = searchPair;
        const mode = environmentState.segmentedValue;

        try {
            setSearchButtonState('loading');

            if (mode === 'Backtest') {
                const backtestsResponse = await atsReportsBacktestsGet(pair);

                if (backtestsResponse.isSuccess) {
                    const executions = (backtestsResponse.response || []).sort((a, b) => new Date(b.backtest_id) - new Date(a.backtest_id));
                    setExecutionState((prevState) => ({
                        ...prevState,
                        mockExecutionsData: executions,
                        selectedExecution: executions.length > 0 ? 0 : null,
                    }));

                    if (executions.length > 0) {
                        const backtest_id = executions[0].backtest_id;
                        await fetchExecutionData(mode, pair, backtest_id);
                    } else {
                        setExecutionState((prevState) => ({
                            ...prevState,
                            chartData: null,
                            filteredSignals: {},
                        }));
                        setPopUpLevel('warning');
                        setPopUpText('No data found for the selected pair.');
                        setPopUpDuration(3000);
                    }

                    setSearchButtonState('success');
                } else {
                    setSearchButtonState('error');
                    setPopUpLevel('error');
                    setPopUpText('Error fetching backtests');
                    setPopUpDuration(3000);
                }
            } else if (mode === 'Default') {
                await fetchExecutionData(mode, pair);
            }
        } catch (error) {
            setSearchButtonState('error');
            setPopUpLevel('error');
            setPopUpText('Request processing error');
            setPopUpDuration(3000);
        }
    };


    // Event Handlers
    const handleSegmentChange = useCallback((value) => {
        setEnvironmentState((prevState) => ({
            ...prevState,
            segmentedValue: value,
        }));

        setExecutionState({
            mockExecutionsData: [],
            selectedExecution: null,
            fullExecutionsData: [],
            chartData: null,
            filteredSignals: {},
        });
    }, []);


    const fetchExecutionData = async (mode, pair, backtest_id = null) => {
        try {
            setSearchButtonState('loading');

            const executionResponse = await atsReportsExecutionsGet({
                mode,
                pair,
                backtest_id,
                simplify_response: true,
            });

            if (executionResponse.isSuccess) {

                const executionDetails = executionResponse.response || [];

                // const { exchange_name, timeframe, timestamp_ms } = executionDetails.executions[0];
                // const exchange = exchangeConstructor(exchange_name);

                // exchange.fetchOHLCV(pair, `${timeframe}m`, timestamp_ms, executionDetails.executions.length)
                //     .then((data) => {
                //         console.log(data);
                //     });

                setSearchButtonState('success');

                setExecutionState((prevState) => ({
                    ...prevState,
                    chartData: generateChartData(executionDetails),
                    fullExecutionsData: executionDetails,
                }));


            } else {
                setSearchButtonState('error');

                setExecutionState((prevState) => ({
                    ...prevState,
                    chartData: null,
                }));
                setPopUpLevel('error');
                setPopUpText('Error fetching execution data');
                setPopUpDuration(3000);
            }

            const signalsResponse = await atsReportsSignalsGet({
                mode,
                pair,
                backtest_id,
            });

            if (signalsResponse.isSuccess) {
                const signalsData = signalsResponse.response || [];
                setExecutionState((prevState) => ({
                    ...prevState,
                    filteredSignals: signalsData,
                }));
            } else {
                setExecutionState((prevState) => ({
                    ...prevState,
                    filteredSignals: [],
                }));
                setPopUpLevel('error');
                setPopUpText('Error fetching signals');
                setPopUpDuration(3000);
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText('Request processing error');
            setPopUpDuration(3000);
        }
    };


    const handleCardClick = useCallback(async (index) => {
        const selected = executionState.mockExecutionsData[index];
        setExecutionState((prevState) => ({
            ...prevState,
            selectedExecution: index,
        }));

        const pair = searchPair;
        const backtest_id = selected.backtest_id;

        await fetchExecutionData(environmentState.segmentedValue, pair, backtest_id);
    }, [executionState.mockExecutionsData, environmentState.segmentedValue]);


    // Export Data to CSV
    const flattenObject = (obj, prefix = '') =>
        Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? `${prefix}.` : '';
            if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
                Object.assign(acc, flattenObject(obj[k], pre + k));
            } else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});


    const exportDataToCSV = useCallback(() => {
        const { filteredSignals = [], fullExecutionsData = {} } = executionState;
        const pair = searchPair;

        if (!pair || !Array.isArray(filteredSignals) || !Array.isArray(fullExecutionsData.executions)) {
            setPopUpLevel('warning');
            setPopUpText('No data to export');
            setPopUpDuration(3000);

            return;
        }

        const flattenedExecutions = fullExecutionsData.executions.map(execution => flattenObject(execution));
        const flattenedSignals = filteredSignals.map(signal => flattenObject(signal));

        const executionCSV = Papa.unparse(flattenedExecutions);
        const signalCSV = Papa.unparse(flattenedSignals);

        const csvContent = `Executions for ${pair}:\n\n${executionCSV}\n\nSignals for ${pair}:\n\n${signalCSV}`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${pair}_data_export.csv`);
    }, [executionState]);


    // Data Generators and Utilities
    const generateChartData = (executionDetails) => {
        const adx = [];
        const diPlus = [];
        const diMinus = [];

        const signals = [];

        executionDetails.executions.forEach((execution) => {
            const buyData = execution.debug?.buy;
            const sellData = execution.debug?.sell;
            const trendLabel = execution?.trend_label;

            if (trendLabel) signals.push({
                time: execution.timestamp_ms / 1000,
                side: '-',
                trendLabel
            });

            if (buyData) {
                adx.push({ time: execution.timestamp_ms / 1000, value: buyData.adx });
                diPlus.push({ time: execution.timestamp_ms / 1000, value: buyData.plus_di });
                diMinus.push({ time: execution.timestamp_ms / 1000, value: buyData.minus_di });

                if (buyData?.entry) {
                    signals.push({
                        time: execution.timestamp_ms / 1000,
                        side: 'buy',
                        divergenceConditionMet: buyData.divergence_condition,
                        slopeConditionMet: buyData.slope_condition,
                        adxMonotonic: buyData.adx_monotonic,
                        trendLabel,
                    });
                }
            }

            if (sellData?.exit) {
                signals.push({
                    time: execution.timestamp_ms / 1000,
                    side: 'sell',
                    divergenceConditionMet: sellData.divergence_condition || false,
                    slopeConditionMet: sellData.slope_condition || false,
                    adxMonotonic: sellData.adx_monotonic || false,
                    trendLabel,
                });
            }
        });

        return { adx, diPlus, diMinus, signals };
    };


    return (
        <Page id={pageId}>
            <Loading id='ats-reports-overlay' />

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                dialogConfirm={false}
                maximizedScreen={false}
                quickSettings='minimal'
                segmentedOptions={['Default', 'Backtest']}
                segmentedValue={environmentState.segmentedValue}
                segmentedOnChange={handleSegmentChange}
                showHeader={true}
                showSegmented={true}
                showToolbar={true}
            >
                {/* Main Content Section */}

                {environmentState.segmentedValue === 'Default' && (
                    <Row
                        a='unset'
                        className='main-row reports-content'
                        fill='all'
                    >
                        <Column id='col-0'
                            fill='height'
                            fit='width'
                            w='6'
                        >
                            <Panel id='default-executions-input'
                                fit='height'
                            >
                                <Title variation='tertiary' className='title' txt={t('Executions')} />

                                <form name='search-execution' onSubmit={handleSearch}>
                                    <Row fit='all' m='pri-ver'>
                                        <Input
                                            className='search-pairs'
                                            onChange={handleSearchPair}
                                            placeholder='BTC/USDT'
                                            variation='text'
                                            value={searchPair}
                                        />

                                        <Button
                                            id='search-execution-button'
                                            state={searchButtonState}
                                            type='submit'
                                        >Search</Button>
                                    </Row>
                                </form>
                            </Panel>

                            <Panel id='signals'
                            >
                                <Title variation='tertiary' txt={t('Signals')} />

                                <hr />
                                {executionState?.filteredSignals && Object.keys(executionState.filteredSignals).length > 0 ? (
                                    <SignalsList
                                        items={executionState.filteredSignals}
                                    />
                                ) : (
                                    <EmptyDisplay message='No signals available' />
                                )}
                            </Panel>
                        </Column>

                        <Panel id='execution-details'
                            fill='all'
                        >
                            <Row
                                fill='width'
                                j='between'
                            >
                                <Title variation='tertiary' txt={t('Execution Details')} />

                                <Button
                                    id='export-to-csv-button'
                                    onClick={() => exportDataToCSV()}
                                    variation={'secondary'}
                                >Export to CSV</Button>
                            </Row>

                            {executionState.chartData ? (
                                <ExecutionChart
                                    data={executionState.chartData}
                                    signals={executionState.chartData.signals}
                                    executionDetails={executionState.mockExecutionsData[executionState.selectedExecution]}
                                    fullExecutionsData={executionState.fullExecutionsData}
                                    theme={environmentState.currentTheme}
                                />
                            ) : (
                                <EmptyDisplay message='No data available' />
                            )}
                        </Panel>
                    </Row>
                )}

                {environmentState.segmentedValue === 'Backtest' && (
                    <Row
                        a='unset'
                        className='main-row reports-content'
                        fill='all'
                    >
                        <Panel id='backtest-executions-input'
                            fill='height'
                            fit='width'
                        >
                            <Title variation='tertiary' className='title' txt={t('Executions')} />

                            <form name='search-execution' onSubmit={handleSearch}>
                                <Row fill='width' m='pri-ver'>
                                    <Input
                                        className='search-pairs'
                                        onChange={handleSearchPair}
                                        placeholder='BTC/USDT'
                                        variation='text'
                                        value={searchPair}
                                    />

                                    <Button
                                        id='search-execution-button'
                                        state={searchButtonState}
                                        type='submit'
                                    >Search</Button>
                                </Row>
                            </form>

                            <hr />
                            {executionState.mockExecutionsData.length > 0
                                ? <ExecutionList
                                    executions={executionState.mockExecutionsData}
                                    selectedExecution={executionState.selectedExecution}
                                    onCardClick={handleCardClick}
                                />
                                : <EmptyDisplay message='No executions available' />
                            }
                        </Panel>

                        <Panel id='signals'
                            fill='height'
                        >
                            <Title variation='tertiary' txt={t('Signals')} />

                            <hr />
                            {Object.keys(executionState.filteredSignals).length > 0 ? (
                                <SignalsList
                                    items={executionState.filteredSignals}
                                />
                            ) : (
                                <EmptyDisplay message="No signals available for this pair" />
                            )}
                        </Panel>

                        <Panel id='execution-details'
                            fill='all'>

                            <Row
                                fill='width'
                                j='between'
                            >
                                <Title variation='tertiary' txt={t('Execution Details')} />

                                <Button
                                    id='export-to-csv-button'
                                    onClick={() => exportDataToCSV()}
                                    variation={'secondary'}
                                >
                                    Export to CSV
                                </Button>
                            </Row>

                            {executionState.chartData ? (
                                <ExecutionChart
                                    data={executionState.chartData}
                                    signals={executionState.chartData.signals}
                                    executionDetails={executionState.mockExecutionsData[executionState.selectedExecution]}
                                    fullExecutionsData={executionState.fullExecutionsData}
                                    theme={environmentState.currentTheme}
                                />
                            ) : (
                                <EmptyDisplay message="No data available" />
                            )}
                        </Panel>
                    </Row>
                )}
            </Layout>
        </Page >
    );
};

export default AtsReports;
