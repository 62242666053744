/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import packageJson from '../../../../package.json';

import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

import { Segmented } from 'antd';

import {
    Column, Header, MessagePopup, Row, Select, SwitchableIcon,
    Ticker, Title, Toolbar, MfaModal
} from 'components/imports';

import { BnxIconLogoSvg, SunSvg, MoonSvg } from 'icons/imports';
import { SmartTradeLogoSvg } from 'images/imports';

import {
    getCurrency as getCookieCurrency, setCurrency as setCookieCurrency,
    getLanguage as getCookieLanguage, setLanguage as setCookieLanguage,
    getSelectedExchange as getCookieExchange, setSelectedExchange as setCookieExchange,
    getTheme as getCookieTheme, setTheme as setCookieTheme
} from 'utils/cookies';

import { pageLoaded } from 'utils/pages';
import { checkAuthorization } from 'utils/token';

import { getGlobalExchanges } from 'utils/exchanges';

import { sessionsMeMfaGet } from 'apis/imports';

import { languages } from 'constants';

import './Layout.css';


class QuickSettingsTypes {
    static BASIC = 'basic'; // Theme Toggler
    static MINIMAL = 'minimal'; // Language Selector and Theme Toggler
    static FULL = 'full'; // Exchange Selector, Language Selector and Theme Toggler
}


const Layout = (props) => {

    // Get version from package.json
    const version = packageJson.version;

    const checkAuth = props.checkAuth;
    const pageId = props.page;
    const quickSettings = props.quickSettings || QuickSettingsTypes.BASIC;
    const showHeader = props.showHeader || false;
    const showSegmented = props.showSegmented || false;
    const showToolbar = props.showToolbar || false;
    const showTopLogo = props.showTopLogo || false;
    const showVersion = props.showVersion || false;
    const segmentedOptions = props.segmentedOptions || [];
    const segmentedValue = props.segmentedValue || '';
    const segmentedOnChange = props.segmentedOnChange || (() => { });
    const tickerTapeCoins = props.tickerTapeCoins || [];

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    // const [, setCurrentStep] = useState(3);
    const [exchanges, setExchanges] = useState([]);

    const [language, setLanguage] = useState(getCookieLanguage());
    const [selectedExchange, setSelectedExchange] = useState(getCookieExchange());
    const [selectedCurrency, setSelectedCurrency] = useState(getCookieCurrency());
    const [theme, setTheme] = useState(getCookieTheme());

    const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [mfaModalVerifyButtonState, setMfaModalVerifyButtonState] = useState('enabled');

    const popUp = props.popUp || { level: 'success', text: '', duration: 0 };


    useEffect(() => {
        changeSelectedCurrency();
        changeSelectedExchange();
        changeLanguage();
        changeTheme();
    }, []);


    useEffect(() => {
        $(document).scrollTop = 0;
        $('html, body').animate({ scrollTop: 0 }, 1);

        let $body = document.body.classList;
        $body.remove(...$body);
        $body.add(pageId + '-page');

        localStorage.setItem('lastVisitedPage', location.pathname);

        changeSelectedCurrency();
        changeSelectedExchange();
        changeLanguage();
        changeTheme();

        setExchanges(getGlobalExchanges({}));

        if (checkAuth) {
            checkAuthorization(navigate, setIsMfaModalOpen, setUsername);
        }

        pageLoaded(pageId);
    }, [location.pathname]);


    const changeSelectedCurrency = (newSelectedCurrency = selectedCurrency) => {
        setSelectedCurrency(newSelectedCurrency);
        setCookieCurrency(newSelectedCurrency);
    };


    const changeSelectedExchange = (newSelectedExchange = selectedExchange) => {
        setSelectedExchange(newSelectedExchange);
        setCookieExchange(newSelectedExchange);
    };


    const changeLanguage = (newLang = language) => {
        setLanguage(newLang);
        setCookieLanguage(newLang);
        // setCurrentStep(3);
        i18n.changeLanguage(newLang);
    };


    const changeTheme = (newTheme = theme) => {
        setTheme(newTheme);
        setCookieTheme(newTheme);

        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(newTheme);
    };

    const handleMfaModalSubmit = async (oauthmfa) => {

        setMfaModalVerifyButtonState('loading');

        var mfa_type = 'TOTP';

        if (oauthmfa === '') {
            setMfaModalVerifyButtonState('error');
            return;
        }

        // Validations to avoid unecessary API calls
        if (oauthmfa.length !== 6) {
            setMfaModalVerifyButtonState('error');
            return;
        }

        setMfaModalVerifyButtonState('loading');

        let result = await sessionsMeMfaGet({
            oauthmfa,
            mfa_type
        });

        $('form #check-code-button').css('cursor', 'pointer');
        if (result.isSuccess) {
            setMfaModalVerifyButtonState('success');
            window.location.reload();
        } else {
            setMfaModalVerifyButtonState('error');
        }
    };


    const moonIcon = <MoonSvg className='icon-svg' />;
    const sunIcon = <SunSvg className='icon-svg' />;

    return (
        <div className={props.page + ' layout browser'} style={{ 'display': 'none' }}>

            <MessagePopup level={popUp.level} text={popUp.text} duration={popUp.duration} />

            <MfaModal
                isOpen={isMfaModalOpen}
                handleSubmit={handleMfaModalSubmit}
                setIsOpen={setIsMfaModalOpen}
                username={username}
                verifyButtonState={mfaModalVerifyButtonState}
            />

            <div className='all-page-content'>

                {showToolbar ? <Toolbar page={props.page} /> : <></>}

                <Column fill='all' m='pri-ver'>
                    {showHeader
                        ? <Header variation='primary'>
                            <Row
                                className='header-section'
                            >
                                <Title variation='primary' txt={t(pageId)} id={`step-${pageId}-title`} />

                                {showSegmented
                                    ? <Segmented
                                        options={segmentedOptions}
                                        value={segmentedValue}
                                        onChange={segmentedOnChange}
                                    />
                                    : <></>
                                }
                            </Row>

                            {tickerTapeCoins.length > 0 ?
                                <Row
                                    fill='width'
                                    j='center'
                                >
                                    <Ticker items={tickerTapeCoins} />
                                </Row>
                                : <></>
                            }

                            <Row
                                className='header-section'
                                j='end'
                            >
                                {quickSettings === QuickSettingsTypes.FULL
                                    ? <>
                                        {/* <Select
                                            className={'currency-dd'}
                                            id={'choose-currency'}
                                            lang={language}
                                            onSelect={changeSelectedCurrency}
                                            options={Object.entries(currencies)}
                                            selected={selectedCurrency}
                                        /> */}

                                        <Select
                                            className={'exchange-dd'}
                                            id={'choose-exchange'}
                                            lang={language}
                                            onSelect={changeSelectedExchange}
                                            options={exchanges}
                                            selected={selectedExchange}
                                            variation='exchange'
                                        />
                                    </>
                                    : <></>}

                                {quickSettings === QuickSettingsTypes.MINIMAL
                                    ? <></>
                                    : (<Select
                                        id={'lang-dd'}
                                        lang={language}
                                        onSelect={changeLanguage}
                                        options={Object.entries(languages)}
                                        selected={languages[language]?.icon}
                                        variation='language'
                                    />)}

                                <SwitchableIcon id="theme-switch-step"
                                    onImage={sunIcon}
                                    isOn={theme === 'dark'}
                                    offImage={moonIcon}
                                    onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                />
                            </Row>
                        </Header>
                        : (showTopLogo
                            ? <Row
                                fill='width'
                                j='between'
                            >
                                <img id='smart-trade-logo' src={SmartTradeLogoSvg} />

                                <Row
                                    className='quick-settings'
                                    j='end'
                                >
                                    <Select
                                        id={'lang-dd'}
                                        lang={language}
                                        onSelect={changeLanguage}
                                        options={Object.entries(languages)}
                                        selected={languages[language]?.icon}
                                        variation='language'
                                    />

                                    <SwitchableIcon id="theme-switch-step"
                                        onImage={sunIcon}
                                        isOn={theme === 'dark'}
                                        offImage={moonIcon}
                                        onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                    />
                                </Row>
                            </Row>
                            : <Row
                                className='quick-settings basic'
                                fill='width'
                                j='between'
                                m='pri-hor'
                            >
                                <Row id='logo'>
                                    <img id='bnx-logo-header' src={BnxIconLogoSvg} />

                                    <img id='smart-trade-logo-header' src={SmartTradeLogoSvg} />
                                </Row>

                                <Row>
                                    <Select
                                        id={'lang-dd'}
                                        lang={language}
                                        onSelect={changeLanguage}
                                        options={Object.entries(languages)}
                                        selected={languages[language]?.icon}
                                        variation='language'
                                    />

                                    <SwitchableIcon id="theme-switch-step"
                                        onImage={sunIcon}
                                        isOn={theme === 'dark'}
                                        offImage={moonIcon}
                                        onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                    />
                                </Row>
                            </Row>)}

                    {props.children}
                </Column>
            </div>

            {showVersion ? <p className='version'>v{version}</p> : <></>}
        </div>
    );
};


Layout.propTypes = {
    checkAuth: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    popUp: PropTypes.object,
    page: PropTypes.string.isRequired,
    quickSettings: PropTypes.string,
    segmentedOnChange: PropTypes.func,
    segmentedOptions: PropTypes.array,
    segmentedValue: PropTypes.string,
    showHeader: PropTypes.bool,
    showSegmented: PropTypes.bool,
    showToolbar: PropTypes.bool,
    showTopLogo: PropTypes.bool,
    showVersion: PropTypes.bool,
    tickerTapeCoins: PropTypes.array
};


export default Layout;
