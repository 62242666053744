import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {
    Integrity, NotFound, Overview, ResetPassword, Rules, Settings, Signals, SignIn, SignUp,
    News, Tools, Unauthorized, Wallet, AtsReports, Ats
} from 'pages/imports';

import './Tokens.css';
import 'styles/Animations.css';
import 'styles/Utils.css';
import './App.css';


function App() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/home" element={<Navigate to="/" />} />
                <Route path="/integrity" element={<Integrity />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/signals" element={<Signals />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/news" element={<News />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/ats" element={<Ats />} />
                <Route path="/ats-reports" element={<AtsReports />} />
                <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
        </Router>
    );
}

export default App;