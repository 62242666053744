
import cryptocurrencies from '../cryptocurrencies.json';


export const getCryptocurrencyName = (symbol) => {

    symbol = symbol.toUpperCase();

    if (symbol in cryptocurrencies)
        return cryptocurrencies[symbol];

    return symbol;
};
