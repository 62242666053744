/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';

import { Column, Input, Panel, Row, Title, SwitchableIcon } from 'components/imports';
import { MoveLeftArrowSvg, MoveRightArrowSvg, loadIcons } from 'icons/imports';
import { abbreviateText } from 'utils/general';
import EmptyDisplay from 'pages/ats-reports/components/EmptyDisplay';


const PortfolioPanel = () => {

    const { t } = useTranslation();

    const [wallet, setWallet] = useState({});

    const [coins, setCoins] = useState([{ symbol: 'BTC', name: 'Bitcoin' }, { symbol: 'ETH', name: 'Ethereum' }]);
    const [coinsSearched, setCoinsSearched] = useState([]);

    const [searchCoins, setSearchCoins] = useState('');
    const [allInWalletSelected, setAllInWalletSelected] = useState(false);
    const [allOutWalletSelected, setAllOutWalletSelected] = useState(false);

    const [someChange, setSomeChange] = useState(false);


    useEffect(() => {
        // Usado para carregar a lista auxiliar no primeiro carregamento
        if (coinsSearched.length == 0) setCoinsSearched(coins);

        setWallet({
            ...wallet,
            items: coins
        });

    }, [coins]);


    useEffect(() => {
        let txt = searchCoins.trim();
        if (txt == '') setCoinsSearched(coins);
        else {
            let found = coins.reduce((acc, coin) => {

                let symbol = coin.symbol.toLowerCase(),
                    name = coin.name.toLowerCase();

                txt = txt.toLowerCase();

                if (symbol.includes(txt) || name.includes(txt))
                    acc.push(coin);

                return acc;
            }, []);

            setCoinsSearched(found);
        }
    }, [searchCoins]);


    useEffect(() => {

        let $sendToWallet = $('.send-into-wallet')[0],
            $takeOutWallet = $('.take-out-of-wallet')[0];

        let checkedCoins = false,
            inWallet = {
                bool: false,
                count: 0
            },
            outWallet = {
                bool: false,
                count: 0
            };

        coinsSearched.forEach(coin => {
            if (coin.checked) checkedCoins = true;

            if (coin.inWallet) {

                if (coin.checked) inWallet.bool = true;
                inWallet.count++;
            }
            else {
                if (coin.checked) outWallet.bool = true;
                outWallet.count++;
            }
        });

        if (checkedCoins) {
            if (inWallet.bool)
                $takeOutWallet.classList.add('active');
            else
                $takeOutWallet.classList.remove('active');

            if (outWallet.bool)
                $sendToWallet.classList.add('active');
            else
                $sendToWallet.classList.remove('active');
        }
        else {
            //$sendToWallet.classList.remove('active');
            //$takeOutWallet.classList.remove('active');
        }

    }, [coins, coinsSearched]);


    const getCoinIndexBySymbol = (symbol) => {
        return coins.findIndex(coin => coin.symbol.toLowerCase() === symbol.toLowerCase());
    };


    const handleSearchCoinsChange = (event) => {
        setSearchCoins(event.target.value);
    };


    const handleToggleCoinCheck = (event) => {
        let symbol = event.target.id.replace('-check', ''),
            updatedList = [...coins];

        if (symbol === 'in-wallet') {

            let newBool = !allInWalletSelected;

            setAllInWalletSelected(newBool);

            updatedList = updatedList.map(coin => {
                if (coin.inWallet) coin.checked = newBool;
                return coin;
            });
        }
        else if (symbol === 'out-wallet') {

            let newBool = !allOutWalletSelected;

            setAllOutWalletSelected(newBool);

            updatedList = updatedList.map(coin => {
                if (!coin.inWallet) coin.checked = newBool;
                return coin;
            });
        }
        else {
            let coinIndex = getCoinIndexBySymbol(symbol);

            if (coinIndex === -1) return;

            updatedList[coinIndex].checked = !updatedList[coinIndex].checked;
        }

        let inWalletCount = 0, inWalletCheckedCount = 0,
            outWalletCount = 0, outWalletCheckedCount = 0;

        updatedList.forEach(coin => {

            if (coin.inWallet) {
                inWalletCount++;
                if (coin.checked) inWalletCheckedCount++;
            }
            else {
                outWalletCount++;
                if (coin.checked) outWalletCheckedCount++;
            }
        });

        if (symbol !== 'in-wallet' && symbol !== 'out-wallet') {
            setAllInWalletSelected(inWalletCount > 0 && inWalletCount === inWalletCheckedCount);
            setAllOutWalletSelected(outWalletCount > 0 && outWalletCount === outWalletCheckedCount);
        }

        setCoins(updatedList);
    };


    const handleSendIntoWallet = () => {

        let someCheck = coins.some(coin => coin.checked);

        if (!someCheck) return;

        let updatedList = coins.reduce((updatedCoins, coin) => {

            if (!coin.inWallet && coin.checked) {
                coin.inWallet = true;
                coin.checked = false;
                setAllOutWalletSelected(false);
            }

            updatedCoins.push(coin);

            return updatedCoins;
        }, []);

        setCoins(updatedList);

        setSomeChange(true);
    };


    const handleTakeOutOfWallet = () => {

        let someCheck = coins.some(coin => coin.checked);

        if (!someCheck) return;

        let updatedList = coins.reduce((updatedCoins, coin) => {

            if (coin.inWallet && coin.checked) {
                coin.inWallet = false;
                coin.checked = false;
                setAllInWalletSelected(false);
            }

            updatedCoins.push(coin);

            return updatedCoins;
        }, []);

        setCoins(updatedList);

        setSomeChange(true);
    };


    const arrowLeftSvg = <MoveLeftArrowSvg className='icon-svg' title='Adicionar à carteira' />;
    const arrowRightSvg = <MoveRightArrowSvg className='icon-svg' title='Remover da carteira' />;


    return (
        <Panel id='portfolio-panel'
            fill='all'
        >
            <Column
                a='center'
                fill='all'
            >
                <Row
                    fill='all'
                    j='between'
                >
                    <Title variation='secondary' txt={t('wallet')} />

                    <Row>
                        <Input
                            id={'search-coin-trend'}
                            onChange={handleSearchCoinsChange}
                            placeholder={t('search.coin')}
                            variation='searchbar'
                            value={searchCoins}
                        />
                    </Row>
                </Row>

                <Row
                    a='center'
                    className='content'
                    fill='all'
                    j='unset'
                >
                    <Column id='monitored-coins'
                        fill='all'
                    >
                        <Row
                            className='table-header'
                            fill='width'
                        >
                            <label className='input-checkbox-container'>
                                <input type='checkbox' id='in-wallet-check' name='in-wallet-check' checked={allInWalletSelected} onChange={handleToggleCoinCheck} />
                                <span className='checkmark'></span>
                            </label>

                            <p><b>{t('cryptocurrency.monitored')}</b></p>
                        </Row>

                        <hr />
                        <Column
                            fill='all'
                            scroll
                        >
                            {
                                coinsSearched.map((data, index) => {

                                    if (!(data?.inWallet ?? false)) return;

                                    let { symbol, name } = data;
                                    let icon = '';

                                    loadIcons([symbol]).then(iconsDict => {
                                        icon = iconsDict;
                                    });

                                    return (
                                        <Row
                                            fill='width'
                                            j='between'
                                            key={index}
                                        >
                                            <label className='input-checkbox-container'>
                                                <input type='checkbox' id={`${data.symbol}-check`} name={`${data.symbol}-check`} checked={data.checked} onChange={handleToggleCoinCheck} />
                                                <span className='checkmark'></span>
                                            </label>

                                            <Row
                                                className='coin-info'
                                                fill='width'
                                            >
                                                <img className='coin-icon' src={icon} />

                                                <Column g='0'>
                                                    <p className='coin-symbol'><b>{symbol}</b></p>
                                                    <p className='coin-name'>{abbreviateText(name, 15)}</p>
                                                </Column>
                                            </Row>
                                        </Row>
                                    );
                                })
                            }
                        </Column>
                    </Column>

                    <Column id='move-buttons'>
                        <SwitchableIcon
                            className='move-button send-into-wallet'
                            onImage={arrowLeftSvg}
                            offImage={arrowLeftSvg}
                            isOn={true}
                            onToggle={handleSendIntoWallet}
                        />
                        <SwitchableIcon
                            className='move-button take-out-of-wallet'
                            onImage={arrowRightSvg}
                            offImage={arrowRightSvg}
                            isOn={true}
                            onToggle={handleTakeOutOfWallet}
                        />
                    </Column>

                    <Column id='available-coins'
                        fill='all'
                    >
                        <Row
                            className='table-header'
                            fill='width'
                        >
                            <label className='input-checkbox-container'>
                                <input type='checkbox' id='out-wallet-check' name='out-wallet-check' checked={allOutWalletSelected} onChange={handleToggleCoinCheck} />
                                <span className='checkmark'></span>
                            </label>

                            <p><b>{t('cryptocurrency.available')}</b></p>
                        </Row>

                        <hr />
                        <Column
                            fill='all'
                            scroll
                        >
                            {coinsSearched
                                .filter(coin => !(coin?.inWallet ?? false)).length > 0
                                ? coinsSearched
                                    .filter(coin => !(coin?.inWallet ?? false))
                                    .map((data, index) => {

                                        let { symbol, name } = data;
                                        let icon = '';

                                        return (
                                            <Row
                                                fill='width'
                                                j='between'
                                                key={index}
                                            >
                                                <label className='input-checkbox-container'>
                                                    <input type='checkbox' id={`${data.symbol}-check`} name={`${data.symbol}-check`} checked={data.checked} onChange={handleToggleCoinCheck} />
                                                    <span className='checkmark'></span>
                                                </label>

                                                <Row
                                                    className='coin-info'
                                                    fill='width'
                                                >
                                                    <img className='coin-icon' src={icon} />

                                                    <Column g='0'>
                                                        <p className='coin-symbol'><b>{symbol}</b></p>
                                                        <p className='coin-name'>{abbreviateText(name, 15)}</p>
                                                    </Column>
                                                </Row>
                                            </Row>
                                        );
                                    })
                                : <Column fill='all'>
                                    <EmptyDisplay message={t('Move assets to your monitored portfolio!')} />
                                </Column>
                            }
                        </Column>
                    </Column>
                </Row>
            </Column>
        </Panel>
    );
};


export default PortfolioPanel;