/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Badge, Card, Column, Date, ExchangesPill, Row, SwitchableIcon } from 'components/imports';

import { getIcon, AtsSvg, DeleteSvg, LoadingImg } from 'icons/imports'; // SVG

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import { getCurrency, getSelectedExchange } from 'utils/cookies';

import { smallBalance } from 'constants';

import './List.css';


const List = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currency,] = useState(getCurrency());
    const [exchange,] = useState(getSelectedExchange().toLowerCase());

    const className = props.className ?? '';

    const deleteIcon = <DeleteSvg className='icon-svg no' />;
    const atsSvg = <AtsSvg className='icon-svg sec' />;


    const handleExpandCoinListItem = (symbol) => {

        $('.list.coin-list .list-item:not(.' + symbol + ') .expandable').addClass('d-none');

        let $expandable = $('.list.coin-list .list-item.' + symbol + ' .expandable');

        $expandable.toggleClass('d-none');
    };


    const redirectToTrends = (id) => {
        localStorage.setItem('article', id);

        let result = confirm(t('ask-confirm-news-redirect'));

        if (!result) { return; }

        navigate('/news');
    };


    switch (props.variation) {

        case 'article-preview':

            return (
                <Column id={props.id} g='0'>
                    {
                        props.items[0].length > 0 && props.items[1].length > 0 ?
                            props.items[0].map((item, index) => {
                                let { fromNow, source, sourceIcon, timestamp, title, url } = item;

                                return (
                                    <>
                                        <hr />

                                        <Card
                                            date={timestamp}
                                            fromNow={fromNow}
                                            id={item.id}
                                            key={index}
                                            lang={props.lang}
                                            onClick={() => props.onItemClick(item.id)}
                                            source={source}
                                            sourceIcon={sourceIcon}
                                            title={title}
                                            variation='article-preview'
                                            url={url}
                                        />
                                    </>
                                );
                            }) :
                            props.items[0].length === 0 && props.items[1].length === 0 ?
                                <Row className='list-message' fill='width' j='center'>
                                    <img className='spinning icon-svg' src={LoadingImg} />
                                </Row> :
                                <Row className='list-message' fill='width' j='center'>
                                    <b>{t('no-results')}</b>
                                </Row>
                    }
                </Column>
            );

        case 'coin-ranking':

            return (
                <Column
                    className={'list coin-list ' + (props.variation + ' ' + className).trim()}
                    id={props.id}
                    fill='width'

                    scroll
                >
                    {
                        props.items.map((item, index) => {

                            var { change, icon, name, pnl, price, symbol } = item;

                            var trend = pnl > 0 ? 'up' : 'down';

                            price = currencyFormatter(price);

                            return (
                                <Row className='list-item' key={symbol} fill='width'>

                                    <Column fill='height'>
                                        <p className='rank-position' id={`rank-position-${index}`}>
                                            <b>{index + 1}º</b>
                                        </p>
                                    </Column>

                                    <Row
                                        className='coin-label'
                                        fill='width'
                                        j='between'

                                    >
                                        <Row className='coin-info'>
                                            <img className='coin-icon' src={icon} />

                                            <Column g='0'>
                                                <p className='coin-symbol'><b>{symbol.toUpperCase()}</b></p>
                                                <p className='coin-name'>{name}</p>
                                            </Column>
                                        </Row>

                                        <Column
                                            a='end'
                                            fill='width'
                                            j='between'

                                        >
                                            <p className='currency'><b>{price}</b></p>

                                            <Row a='center'>
                                                <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                                    <b>{percentageFormatter({ v: pnl })}</b>
                                                </p>
                                                <p className={`currency trend-${trend}-text`}>(${change})</p>
                                            </Row>
                                        </Column>
                                    </Row>
                                </Row>
                            );
                        })
                    }
                </Column >
            );

        case 'coin-variation':

            return (
                <Column
                    className={'list coin-list ' + props.variation}
                    fill='all'
                    id={props.id}
                    j='between'
                >
                    {
                        props.items.map((item) => {

                            var { icon, name, pnl, price, symbol } = item;

                            pnl /= 100;
                            price = currencyFormatter(price);

                            return (
                                <Row fill='width' key={symbol + '/USDT'}>
                                    <Row
                                        className='coin-info'
                                        fill='width'
                                    >
                                        <img className='coin-icon' src={icon} />

                                        <Column g='0'>
                                            <p className='coin-symbol'><b>{symbol}</b></p>
                                            <p className='coin-name'>{name}</p>
                                        </Column>
                                    </Row>

                                    <Column a='end' g='0'>
                                        <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                            <b>{percentageFormatter({ v: pnl })}</b>
                                        </p>

                                        <p className='currency'>{price}</p>
                                    </Column>
                                </Row>
                            );
                        })
                    }
                </Column>
            );

        case 'news-articles':

            return (
                <Column
                    className='list scroll'
                    fill='width'
                    g='0'
                    id={props.id}
                >
                    {
                        props.items.length > 0 ?
                            props.items.map((item, index) => {
                                let { fromNow, source, sourceIcon, timestamp, title, url } = item;

                                return (
                                    <Column fill='width' id={'article-' + index} key={'article-' + index}>
                                        <hr />

                                        <Card
                                            date={timestamp}
                                            fromNow={fromNow}
                                            id={item.id}
                                            key={index}
                                            lang={props.lang}
                                            onClick={() => redirectToTrends(item.id)}
                                            showAllTitle={true}
                                            source={source}
                                            sourceIcon={sourceIcon}
                                            title={title}
                                            url={url}
                                            variation='article-preview'
                                        />
                                    </Column>
                                );
                            }) :
                            props.items.length === 0 ?
                                <Row className='list-message' fill='width' j='center'>
                                    <img className='spinning icon-svg' src={LoadingImg} />
                                </Row> :
                                <Row className='list-message' fill='width' j='center'>
                                    <b>{t('no-results')}</b>
                                </Row>
                    }
                </Column>
            );

        case 'positions':

            return (
                <Column id={props.id}
                    className={'list coin-list scroll ' + props.variation}
                    fill='width'
                >
                    <Column
                        fill='width'
                        g='0'
                    >
                        {
                            props.items.map((item) => {

                                var { balance, icon, name, perExchange, symbol, total } = item;

                                var pnl;
                                if (exchange in perExchange)
                                    pnl = perExchange[exchange].pnl;

                                if (props.hideSmallPositions && balance < smallBalance)
                                    return <></>;

                                return (
                                    <Column
                                        className={'list-item ' + symbol}
                                        fill='width'
                                        g='0'
                                        key={symbol}
                                        onClick={() => handleExpandCoinListItem(symbol)}
                                    >
                                        <hr />

                                        <Column
                                            className='card'
                                            fill='width'
                                        >
                                            <Row
                                                a='center'
                                                fill='width'
                                                j='between'
                                            >

                                                <Row
                                                    className='coin-info'
                                                    fit='width'
                                                >
                                                    <img className='coin-icon' src={icon} />

                                                    <Column g='0'>
                                                        <p className='coin-symbol'><b>{symbol}</b></p>

                                                        <p className='coin-name'>{name}</p>
                                                    </Column>
                                                </Row>

                                                <ExchangesPill exchanges={Object.keys(perExchange)} />
                                            </Row>

                                            <Row
                                                fill='width'
                                                j='between'
                                            >
                                                <p>{t('total')}</p>

                                                <Column
                                                    a='end'
                                                    fit='width'
                                                    g='0'
                                                >
                                                    {
                                                        symbol !== currency
                                                            ?
                                                            <p className={'amount fill-width ' + (props.hideValues ? 'occult' : '')}>
                                                                <b>{currencyFormatter(total || 0)}</b>
                                                            </p>
                                                            : <></>
                                                    }

                                                    <Row>
                                                        <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                            {currencyFormatter(balance || 0)}
                                                        </p>

                                                        <p className='money-currency'>
                                                            {currency}
                                                        </p>
                                                    </Row>

                                                    {pnl
                                                        ? <p className={'trend-' + (pnl > 0 ? 'up' : 'down') + '-text'}>
                                                            <b>{pnl}%</b>
                                                        </p>
                                                        : <></>
                                                    }
                                                </Column>
                                            </Row>

                                            {/*
                                            <Row
                                                a='center'
                                                fill='width'
                                                j='between'
                                            >
                                                <Row
                                                    a='center'
                                                    g='0'
                                                >
                                                    {atsSvg} <p>ATS</p>
                                                </Row>

                                                <Column
                                                    a='end'
                                                    fit='width'
                                                    g='0'
                                                >
                                                    {
                                                        symbol !== currency
                                                            ?
                                                            <p className={'amount fill-width ' + (props.hideValues ? 'occult' : '')}>
                                                                <b>{+(0).toFixed(8)}</b>
                                                            </p>
                                                            : <p>0</p>
                                                    }

                                                    <Row>
                                                        <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                            {+(0).toFixed(8)}
                                                        </p>

                                                        <p className='money-currency'>
                                                            {currency}
                                                        </p>
                                                    </Row>

                                                    {pnl
                                                        ? <p className={'trend-' + (pnl > 0 ? 'up' : 'down') + '-text'}>
                                                            <b>{pnl}%</b>
                                                        </p>
                                                        : <p>--%</p>
                                                    }
                                                </Column>
                                            </Row>
                                            */}
                                        </Column>

                                        <Column className='expandable d-none' g='0'>
                                            {
                                                Object.keys(perExchange).map((exchange) => {

                                                    let { balance, last, pnl, total } = perExchange[exchange];

                                                    return (<Row
                                                        a='center'
                                                        fill='width'
                                                        j='between'
                                                        key={exchange}
                                                        m='pri-hor'
                                                        p='pri'
                                                    >
                                                        <img
                                                            alt={exchange + ' logo'}
                                                            className='asset-icon'
                                                            src={getIcon(exchange)}
                                                            title={exchange}
                                                        />

                                                        <Column
                                                            a='end'
                                                            fill='width'
                                                            g='0'
                                                        >
                                                            {
                                                                symbol !== currency
                                                                    ? <p className={'amount ' + (props.hideValues ? 'occult' : '')}>
                                                                        <b>{total}</b>
                                                                    </p>
                                                                    : <></>
                                                            }

                                                            <Row>
                                                                {
                                                                    balance
                                                                        ? <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                                            {currencyFormatter(balance)}
                                                                        </p>
                                                                        : <p>--</p>
                                                                }

                                                                <p className='money-currency'>
                                                                    {currency}
                                                                </p>
                                                            </Row>

                                                            {pnl
                                                                ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                                    {last} ({(pnl >= 0 ? '+' : '') + pnl}%)
                                                                </p>
                                                                : <></>
                                                            }
                                                        </Column>
                                                    </Row>);
                                                })
                                            }
                                        </Column>
                                    </Column>
                                );
                            })
                        }
                    </Column>
                </Column>
            );

        case 'signals':

            return (
                <Column className='list signals scroll' fill='width' id={props.id}>
                    {
                        props.items.length > 0 ?
                            <>
                                <Row id='signals-list-header'
                                    fill='width'
                                    j='between'
                                >
                                    <label className='col-0'><b>{t('time')}</b></label>
                                    <label className='col-1'><b>{t('pair')}</b></label>
                                    <label className='col-2' ><b>{t('price.s')}</b></label>
                                    <label className='col-3' ><b>{t('side.s')}</b></label>
                                    <label className='col-4' ><b>{t('pnl')}</b></label>
                                </Row>

                                <Column fill='width'>
                                    {
                                        props.items.map((signal, index) => {

                                            let { iconBase, iconQuote, pnl, pairName, pairSymbol, price, side, timestamp } = signal;

                                            return (
                                                <Row
                                                    fill='width'
                                                    j='between'
                                                    key={'signal-' + index}
                                                >
                                                    <Date className='col-0' timestamp={timestamp} />

                                                    <Row className='coin-info col-1'>
                                                        <img className='coin-icon base-icon' src={iconBase} />

                                                        <img className='coin-icon quote-icon' src={iconQuote} />

                                                        <Column g='0'>
                                                            <p className='coin-symbol'><b>{pairSymbol}</b></p>

                                                            <p className='coin-name'>{pairName}</p>
                                                        </Column>
                                                    </Row>

                                                    <p className='col-2'>
                                                        <b>{currencyFormatter(price)}</b>
                                                    </p>
                                                    <Badge
                                                        className='col-3'
                                                        variation={side}
                                                    >
                                                        {side.toUpperCase()}
                                                    </Badge>
                                                    <p className={'col-4 pnl trend-' + (pnl ? (pnl > 0 ? 'up' : 'down') : 'neutral') + '-text'}>
                                                        <b>{pnl ? percentageFormatter({ v: pnl }) : '-'}</b>
                                                    </p>
                                                </Row>
                                            );
                                        })
                                    }
                                </Column>
                            </>
                            : <Row fill='width' j='center'><b>{t('no-results')}</b></Row>
                    }
                </Column>
            );

        case 'user-exchanges':

            return (
                <Column id={props.id}
                    className='list user-exchanges'
                    fill='width'
                    scroll
                >
                    {
                        props.items.length > 0 ?
                            props.items.map((item, index) => {

                                let { name, credentials } = item;
                                let { apiKey, secret, password } = credentials;

                                return (
                                    <Column
                                        className='item added'
                                        fill='width'
                                        j='between'
                                        key={'added-exchange-' + index}
                                        p='pri'
                                    >
                                        <Row
                                            fill='width'
                                            j='between'
                                        >
                                            <Row>
                                                <img src={getIcon(name)} alt={name + ' icon'} />

                                                <p className='title'><b>{name}</b></p>
                                            </Row>

                                            <SwitchableIcon
                                                id='close-add-exchange-icon'
                                                onToggle={() => props.deleteExchangeConnection(name)}
                                                staticImage={deleteIcon}
                                                title='Delete connection'
                                            />
                                        </Row>

                                        <Row
                                            fit='all'
                                            g='2'
                                            j='between'
                                        >
                                            <Column
                                                fit='all'
                                                g='0'
                                            >
                                                <label><b>{t('api-key')}</b></label>
                                                <label>{apiKey}</label>
                                            </Column>

                                            <Column
                                                fit='all'
                                                g='0'
                                            >
                                                <label><b>{t('secret.s')}</b></label>
                                                <label>{secret}</label>
                                            </Column>

                                            {password
                                                ?
                                                <Column
                                                    fit='all'
                                                    g='0'
                                                >
                                                    <label><b>{t('password.s')}</b></label>
                                                    <label>{password}</label>
                                                </Column>
                                                : <></>
                                            }
                                        </Row>
                                    </Column>
                                );

                            }) : <Row className='list-message' fill='width' j='center'>
                                {t('no-exchanges-connected')}
                            </Row>
                    }
                </Column>
            );

        default:
    }
};


List.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    deleteExchangeConnection: PropTypes.func,
    hideSmallPositions: PropTypes.bool,
    hideValues: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.array,
    lang: PropTypes.string,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    variation: PropTypes.string
};


export default List;
