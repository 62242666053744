/* eslint-disable @typescript-eslint/no-unused-vars */


import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
// import i18n from 'locales/i18n';

import { useTour } from '@reactour/tour';
import {
    stepsOverview,
    stepSignals,
    stepDashboard,
    stepTrendAndNews,
    stepWallet,
    stepsSettings,
} from 'steps';

import './Toolbar.css';

import {
    ConfirmSignOut,
    PageLink,
    SwitchableIcon
} from 'components/imports';

import {
    BnxIconLogo128Png, /*DashboardSvg,*/ DocumentSvg, HomeSvg, ListSvg, LockSvg, OverviewSvg, QuestionSvg, SettingsSvg, SignOutSvg, /*SignalsSvg,*/
    NewsSvg, UnlockSvg, UserDefaultPic128Png, WalletSvg, AtsSvg, AtsReportsSvg,
    Plan0Svg, PlanAdminSvg, SignalsSvg
} from 'icons/imports';

import { SmartTradeLogoPng } from 'images/imports';

import { sessionsMeDelete, usersMeGet, usersMeAvatarGet } from 'apis/imports';

import { pagesWithToolbar } from 'constants';

import { Button, Column, Row } from 'components/imports';

import { getUserFromCookie } from 'utils/cookies';


const Toolbar = (props) => {

    const page = props.page;

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [showActions, setShowActions] = useState(false);
    const [selectedDropdown, setSelectedDropdown] = useState('');

    const [signOutModalEnabled, setSignOutModalEnabled] = useState(false);
    const [user, setUser] = useState(getUserFromCookie());
    const [avatar, setAvatar] = useState(UserDefaultPic128Png);
    const [path] = useState(location.pathname);

    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    // var dashboardSvg = <DashboardSvg className='icon-svg'></DashboardSvg>;
    var documentSvg = <DocumentSvg className='icon-svg'></DocumentSvg>;
    var questionSvg = <QuestionSvg className='icon-svg'></QuestionSvg>;
    var overviewSvg = <OverviewSvg className='icon-svg'></OverviewSvg>;
    // var signalsSvg = <SignalsSvg className='icon-svg'></SignalsSvg>;
    var trendsAndNewsSvg = <NewsSvg className='icon-svg'></NewsSvg>;
    var walletSvg = <WalletSvg className='icon-svg'></WalletSvg>;
    var atsSvg = <AtsSvg className='icon-svg'></AtsSvg>;
    var atsReportsSvg = <AtsReportsSvg className='icon-svg'></AtsReportsSvg>;

    var settingsSvg = <SettingsSvg className='icon-svg'></SettingsSvg>;
    var signalsSvg = <SignalsSvg className='icon-svg'></SignalsSvg>;
    var signOutSvg = <SignOutSvg id='sign-out' className='icon-svg'></SignOutSvg>;

    const [isHoverActive, setIsHoverActive] = useState(false);
    const [isToolbarLocked, setIsToolbarLocked] = useState(localStorage.getItem('isToolbarLocked') === 'true' ? true : false);
    const timerRef = useRef(null);

    const plan0Icon = <Plan0Svg className='icon-svg'></Plan0Svg>;
    const planAdminIcon = <PlanAdminSvg className='icon-svg'></PlanAdminSvg>;
    const lockIcon = <LockSvg className='icon-svg'></LockSvg>;
    const unlockIcon = <UnlockSvg className='icon-svg'></UnlockSvg>;

    useEffect(() => {
        if (pagesWithToolbar.map(x => '/' + x === location.pathname).some(x => x === true)) {
            setShowActions(false);
        }
        getUser();
    }, [location.pathname]);


    $(document).on('click', (e) => {

        var foundId = false;

        const idSearchOnHierarchy = (e, id) => {

            var node = e;

            if ('target' in e) {
                foundId = false;
                node = e.target;
            }

            if (foundId) return;
            else {
                if (node && 'id' in node && node.id.includes(id)) {
                    foundId = true;
                    return;
                }
                else {
                    if (!node.parentNode) {
                        foundId = false;
                        return;
                    }
                    else {
                        idSearchOnHierarchy(node.parentNode, id);
                    }
                }
            }
        };

        (idSearchOnHierarchy(e, 'actions-dd'));
        if (foundId) return;

        try {

            setShowActions(false);

            if (selectedDropdown !== '') {
                switch (selectedDropdown) {
                    case 'actions-dd':
                        setShowActions(!showActions);
                        break;
                    default:
                        console.error('There is no expected case for selectedDropdown as ' + selectedDropdown);
                        break;
                }
                setSelectedDropdown('');
            }
        }
        catch (err) { console.error(err); }

    });


    useEffect(() => {
        if (showActions) {
            $('#actions-dd').fadeIn();
            $('#user-email').fadeIn();
        }
        else {
            $('#actions-dd').fadeOut();
            $('#user-email').fadeOut();
        }
    }, [showActions]);


    useEffect(() => {
        localStorage.setItem('isToolbarLocked', isToolbarLocked);
    }, [isToolbarLocked]);


    const signOutUser = async () => {
        const result = await sessionsMeDelete();

        if (result.isSuccess) navigate('/sign-in');
        else navigate('/unauthorized');
    };


    const getUser = async () => {

        const userResult = await usersMeGet();

        if (userResult && userResult.response) {
            setUser(userResult.response);

            const userAvatarResult = await usersMeAvatarGet();

            if (userAvatarResult && userAvatarResult.response) {
                setAvatar(userAvatarResult.response.avatar_url);
            }
        }
    };


    const verifyTour = () => {
        switch (path) {

            case '/overview':
                setSteps(stepsOverview);
                break;

            case '/news':
                setSteps(stepTrendAndNews);
                break;

            case '/signals':
                setSteps(stepSignals);
                break;

            case '/wallet':
                setSteps(stepWallet);
                break;

            case '/settings':
                setSteps(stepsSettings);
                break;

            case '/dashboard':
                setSteps(stepDashboard);
                break;
        }
    };


    const handleRestartTour = () => {
        verifyTour();
        setCurrentStep(0);
        setIsOpen(true);
    };


    const handleSignOutButton = (event) => {

        var option = event.target.id.replace('-btn', '');

        if (option === 'yes') signOutUser();
        else setSignOutModalEnabled(false);
    };


    const handleClickOutDialogModal = (event) => {

        event.preventDefault();

        if (event.target.classList.contains('sign-out-dialog') ||
            event.target.classList.contains('secondary')) {
            setSignOutModalEnabled(false);
        }
    };


    const handleMouseEnter = () => {
        timerRef.current = setTimeout(() => setIsHoverActive(true), 200);
    };


    const handleMouseLeave = () => {
        clearTimeout(timerRef.current);
        setIsHoverActive(false);
        setSelectedDropdown('');
    };


    return (
        <>
            <ConfirmSignOut
                handle={handleSignOutButton}
                handleClickOut={handleClickOutDialogModal}
                showDialog={signOutModalEnabled}
                text={t('are-you-ure-to-sing-out')}
            />

            <Column
                className={'browser' + (isHoverActive || isToolbarLocked ? ' hover' : '')}
                id='toolbar-container'
                j='between'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Column>
                    <Row id='logo-container'
                        fill='width'
                        g='0'
                    >
                        <img id='bnx-logo-img' src={BnxIconLogo128Png} alt='BNX Logo' />

                        <img id='st-logo-img' src={SmartTradeLogoPng} alt='Smart Trade Logo' />
                    </Row>

                    <Column id='pages'
                        className='toolbar'
                        g='0'
                    >
                        <PageLink
                            beta={true}
                            id='overview-page-link'
                            isSelected={page === 'overview'}
                            route='/overview'
                            showText={true}
                            svgIcon={overviewSvg}
                            text={t('overview')}
                        />

                        <PageLink
                            beta={true}
                            id='signals-page-link'
                            isSelected={page === 'signals'}
                            route='/signals'
                            showText={true}
                            svgIcon={signalsSvg}
                            text={'Signals'}
                        />

                        <PageLink
                            beta={true}
                            id='dashboard-page-link'
                            isSelected={page === 'news'}
                            route='/news'
                            showText={true}
                            svgIcon={trendsAndNewsSvg}
                            text='News'
                        />

                        {/* <PageLink
                            beta={true}
                            id='wallet-page-link'
                            isSelected={page === 'wallet'}
                            route='/wallet'
                            showText={true}
                            svgIcon={walletSvg}
                            text={'Wallet'}
                        /> */}

                        {/*<PageLink
                            beta={true}
                            id='ats-page-link'
                            isSelected={page === 'ats'}
                            route='/ats'
                            showText={true}
                            svgIcon={atsSvg}
                            text={'ATS'}
                        />*/}

                        {user?.role === 'ADMIN'
                            ? <PageLink
                                beta={true}
                                id='ats-reports-page-link'
                                isSelected={page === 'ats-reports'}
                                route='/ats-reports'
                                showText={true}
                                svgIcon={atsReportsSvg}
                                text={'ATS Reports'}
                            />
                            : <></>
                        }

                        <PageLink
                            beta={true}
                            id='settings-page-link'
                            isSelected={page === 'settings'}
                            route='/settings'
                            showText={true}
                            svgIcon={settingsSvg}
                            text='Settings'
                        />
                    </Column>
                </Column>

                <Column id='user-preferences'
                    className='toolbar'
                >
                    <Column id='actions-dd'
                        className='dropdown'
                    >
                        <p className='title'>{t('corporate')}</p>

                        <SwitchableIcon
                            className='page-link-button'
                            showText={true}
                            staticImage={documentSvg}
                            onToggle={() => window.open('https://www.we-bronx.io/docs/en-US/cookies-policy.pdf', '_blank')}
                            text={t('cookies.messages.title')}
                        />

                        <SwitchableIcon
                            className='page-link-button'
                            showText={true}
                            staticImage={documentSvg}
                            onToggle={() => window.open('https://www.we-bronx.io/docs/en-US/privacy-policy.pdf', '_blank')}
                            text={t('privacy-policy')}
                        />

                        <SwitchableIcon
                            className='page-link-button'
                            showText={true}
                            staticImage={documentSvg}
                            onToggle={() => window.open('https://www.we-bronx.io/docs/en-US/terms-of-service.pdf', '_blank')}
                            text={t('terms-of-service.p')}
                        />

                        <p className='title'>{t('action.p')}</p>

                        {/* Restart Tour
                        <SwitchableIcon
                            className='page-link-button'
                            showText={true}
                            staticImage={questionSvg}
                            onToggle={() => handleRestartTour()}
                            text={t('restart-tour')}
                        />*/}
                        <SwitchableIcon id='sign-out-btn' /* Sign Out */
                            className='page-link-button'
                            onToggle={() => setSignOutModalEnabled(true)}
                            showText={true}
                            staticImage={signOutSvg}
                            text={t('sign.out.v')}
                        />
                    </Column>

                    <Column g='0'>
                        {user
                            ? <Row id='session-user'
                                className='page-link-button'
                                fill='width'
                                g='0'
                                onClick={() => setSelectedDropdown('actions-dd')}
                                title={`${user.fullname} (${user.username}) [${user.role}]`}
                            >
                                <img id='session-pic' src={avatar} alt='User profile' />

                                <p id='session-fullname'>{user.fullname}</p>
                            </Row>
                            : <></>
                        }

                        {user?.role
                            ? <Row id='session-plan'
                                className='page-link-button'
                                fill='width'
                            >
                                {user?.role === 'ADMIN' ? planAdminIcon : plan0Icon}

                                <p>{user?.role === 'ADMIN' ? 'Administrator' : t('upgrade-plan')}</p>
                            </Row>
                            : <></>
                        }
                    </Column>

                    <Button id='lock-button'
                        onClick={() => setIsToolbarLocked(!isToolbarLocked)}
                        variation='secondary'
                    >
                        {isToolbarLocked ? lockIcon : unlockIcon}
                    </Button>
                </Column>
            </Column>
        </>
    );
};


Toolbar.propTypes = {
    page: PropTypes.string
};


export default Toolbar;
