/* eslint-disable react/prop-types */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */

// React and core libraries
import React, { useState, useEffect } from 'react';

import i18n from 'locales/i18n';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';

// Cookies, Tour, and i18n
import { useTour } from '@reactour/tour';
import { stepWallet } from 'steps';

// Custom Components
import {
    Loading,
    Column, Layout, Page, Row,
    MessagePopup
} from 'components/imports';

// Icons and Images
import { loadIcons } from 'icons/imports';

// API calls
import { balancesMeGet } from 'apis/imports';

// Utilities
import { getLanguage, getTheme } from 'utils/cookies';
import { getCryptocurrencyName } from 'utils/cryptocurrencies';

import { BalancesPanel } from 'components/imports';
import AssetsDistributionPanel from './components/AssetsDistributionPanel';
import OrdersHistoryCard from './components/OrdersHistoryCard';

import PortfolioPanel from './components/PortfolioPanel';

// Styles
import './Wallet.css';



const Wallet = () => {

    const { t } = useTranslation();

    const pageId = 'wallet';

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());

    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    const [popUpDuration, setPopUpDuration] = useState(3000);

    // Wallet Data
    const [portfolioData, setPortfolioData] = useState({});
    const [loadingAssets, setLoadingAssets] = useState(true);

    // Portfolio Donnut Chart
    const [portfolioChart, setPortfolioChart] = useState([]);
    const [selectedData, setSelectedData] = useState('selectedExchange');

    const { setIsOpen, setSteps, setCurrentStep } = useTour();


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('wallet')} - Smart Trade`;

            $(document).scrollTop = 0;
            $('html, body').animate({ scrollTop: 0 }, 1);

            let $body = document.body.classList;
            $body.remove(...$body);
            $body.add('wallet-page');

            localStorage.setItem('lastVisitedPage', location.pathname);

            fetchAPI();
        }
    }, [location.pathname]);


    useEffect(() => {
        let totalValue = portfolioData?.general?.total;

        if (totalValue) {
            if (selectedData === 'selectedExchange') {
                totalValue = portfolioData.perSymbol.reduce((acc, symbolData) => {
                    return acc + (symbolData.total.USDT || 0);
                }, 0);

                const symbolDistribution = portfolioData.perSymbol.map(symbolData => {
                    const usdtValue = symbolData.total.USDT || 0;
                    return { type: symbolData.symbol, value: (usdtValue / totalValue) };
                });

                setPortfolioChart(symbolDistribution);

            } else if (selectedData === 'connectedExchanges') {
                totalValue = Object.values(portfolioData.general.perExchange).reduce((acc, value) => acc + value, 0);

                const exchangeDistribution = Object.entries(portfolioData.general.perExchange).map(([exchange, value]) => {
                    return { type: exchange, value: (value / totalValue) };
                });

                setPortfolioChart(exchangeDistribution);
            }
        }
    }, [selectedData]);



    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    const fetchAPI = async () => {

        await getPortfolioData();
    };


    const getPortfolioData = async () => {

        const result = await balancesMeGet();

        setLoadingAssets(false);

        if (!result.isSuccess) return;

        var general = result.response.general;

        var perSymbol = result.response.perSymbol.map((value) => ({
            ...value,
            name: getCryptocurrencyName(value.symbol)
        }));

        let iconNames = [];

        perSymbol = perSymbol.reduce((acc, item) => {
            iconNames.push(item.symbol.toLowerCase());
            acc.push({ ...item, name: getCryptocurrencyName(item.symbol) });
            return acc;
        }, []);


        loadIcons(iconNames).then(imagesDict => {

            perSymbol = perSymbol.map(item => ({
                ...item,
                icon: imagesDict[item.symbol.toLowerCase()]
            }));

            setPortfolioData({
                general,
                perSymbol,
            });
        });
    };


    return (
        <Page id={pageId} >
            <Loading id={'wallet-overlay'} />

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                showHeader={true}
                showToolbar={true}
            >
                <Row
                    a='unset'
                    className='main-row'
                    fill='width'
                >
                    {/* First Column */}
                    <Column w='4'>
                        {/* Wallet */}
                        <BalancesPanel
                            portfolioData={portfolioData}
                            loadingAssets={loadingAssets} />
                    </Column>

                    {/* Second Column */}
                    <Column className='w-20'>
                        <Row id='portfolio-panels-row'
                            fill='width'>
                            <PortfolioPanel />

                            <AssetsDistributionPanel
                                loading={loadingAssets}
                                portfolioChart={portfolioChart}
                                selectedData={selectedData}
                                setSelectedData={setSelectedData}
                                t={t}
                            />
                        </Row>
                        {/*
                            <AntdRow>
                                <AntdCol span={18} style={{ height: '100%', paddingRight: '16px' }}>
                                    <PortfolioCard />
                                </AntdCol>

                                <AntdCol span={6} style={{ height: '100%' }}>
                                    <AssetsDistributionPanel
                                        portfolioChart={portfolioChart}
                                        selectedData={selectedData}
                                        setSelectedData={setSelectedData}
                                        t={t}
                                    />
                                </AntdCol>
                            </AntdRow>

                            <AntdRow>
                                <AntdCol span={24} style={{ height: '100%' }}>
                                    <OrdersHistoryCard />
                                </AntdCol>
                            </AntdRow>
                            */}
                    </Column>
                </Row>
            </Layout>
        </Page >
    );
};

export default Wallet;
